import HeroSection from "../../components/HeroSection";
import DoctorIntro from "../../components/DrGamIntro";
import MedicineSection from "../../components/MedicineSection";
import Questions from "../../components/Questions";
import IgniteRF from "../../components/IgniteRF";
import DualVideoSection from "../../components/DualVideo";
import EarthScrollSection from "../../components/earth/earth";

const HomePage: React.FC<{}> = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto ">
        <HeroSection />
      </div>
      <MedicineSection />
      <IgniteRF />
      <DualVideoSection />
      <DoctorIntro />
      <EarthScrollSection />
      {/* <div className="mt-[10rem]"> */}
      <Questions />
      {/* </div> */}
    </>
  );
};
export default HomePage;
