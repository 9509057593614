import { useState } from "react";
import { axiosInstance } from "../constants";
import { Loader2, RefreshCw, Users, Calendar } from "lucide-react";
import { Alert } from "@mui/material";

interface Contact {
  _id: string;
  consent: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  sujet: string;
  message: string;
}

interface Appointment {
  _id: string;
  FullName: string;
  appointmentDate: string;
  phoneNumber: number;
}

const AdminComponent = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [activeTab, setActiveTab] = useState<'contacts' | 'appointments'>('contacts');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchContacts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get<Contact[]>("/contact/api");
      if (response.data) {
        setContacts(response.data);
      }
    } catch (error) {
      setError("Failed to fetch contacts. Please try again later.");
      console.error("Error fetching contacts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAppointments = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get<Appointment[]>("/appointments/api");
      if (response.data) {
        setAppointments(response.data);
      }
    } catch (error) {
      setError("Failed to fetch appointments. Please try again later.");
      console.error("Error fetching appointments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = async (tab: 'contacts' | 'appointments') => {
    setActiveTab(tab);
    if (tab === 'contacts') {
      await fetchContacts();
    } else {
      await fetchAppointments();
    }
  };

  const handleRefresh = () => {
    if (activeTab === 'contacts') {
      fetchContacts();
    } else {
      fetchAppointments();
    }
  };

  return (
    <div className="p-6 bg-gray100 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 mt-20">Admin Dashboard</h1>
        
        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => handleTabChange('contacts')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'contacts'
                ? 'bg-gray800 text-lightB'
                : 'bg-lightB text-gray800 hover:bg-gray100'
            }`}
          >
            <Users className="w-4 h-4 mr-2" />
            Contacts
          </button>
          <button
            onClick={() => handleTabChange('appointments')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'appointments'
                ? 'bg-gray800 text-lightB'
                : 'bg-lightB text-gray800 hover:bg-gray100'
            }`}
          >
            <Calendar className="w-4 h-4 mr-2" />
            Appointments
          </button>
          <button
            onClick={handleRefresh}
            disabled={isLoading}
            className="ml-auto flex items-center px-4 py-2 rounded-lg bg-lightB text-gray800 hover:bg-gray100 transition-colors"
          >
            <RefreshCw className={`w-4 h-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
            Refresh
          </button>
        </div>

        {error && (
          <Alert severity="error" className="mb-6">
            {error}
          </Alert>
        )}

        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <Loader2 className="w-8 h-8 animate-spin text-gray800" />
          </div>
        ) : (
          <>
            {activeTab === 'contacts' && (
              <div className="overflow-hidden rounded-lg border border-gray200">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray200">
                    <thead className="bg-gray800">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Phone</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Subject</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Message</th>
                      </tr>
                    </thead>
                    <tbody className="bg-lightB divide-y divide-gray200">
                      {contacts.length === 0 ? (
                        <tr>
                          <td colSpan={5} className="px-6 py-4 text-center text-gray800">
                            No contacts found
                          </td>
                        </tr>
                      ) : (
                        contacts.map((contact) => (
                          <tr key={contact._id} className="hover:bg-gray100">
                            <td className="px-6 py-4 lightBspace-nowrap">{contact.name}</td>
                            <td className="px-6 py-4 lightBspace-nowrap">{contact.email}</td>
                            <td className="px-6 py-4 lightBspace-nowrap">{contact.phoneNumber}</td>
                            <td className="px-6 py-4 lightBspace-nowrap">{contact.sujet}</td>
                            <td className="px-6 py-4">
                              <div className="max-w-xs truncate">{contact.message}</div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {activeTab === 'appointments' && (
              <div className="overflow-hidden rounded-lg border border-gray200">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray200">
                    <thead className="bg-gray800">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Full Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Appointment Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-lightB uppercase tracking-wider">Phone Number</th>
                      </tr>
                    </thead>
                    <tbody className="bg-lightB divide-y divide-gray200">
                      {appointments.length === 0 ? (
                        <tr>
                          <td colSpan={3} className="px-6 py-4 text-center text-gray300">
                            No appointments found
                          </td>
                        </tr>
                      ) : (
                        appointments.map((appointment) => (
                          <tr key={appointment._id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 lightBspace-nowrap">{appointment.FullName}</td>
                            <td className="px-6 py-4 lightBspace-nowrap">{appointment.appointmentDate}</td>
                            <td className="px-6 py-4 lightBspace-nowrap">{appointment.phoneNumber}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminComponent;