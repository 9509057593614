import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import ContactPage from "./pages/contactPage/contact.page";
import PageContainer from "./layouts/pageContainer";
import { AuthProvider } from "./context/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import Corps from "./pages/Corps/corps";
import ScrollToTop from "./components/ScrollToTop";
import Seins from "./pages/Seins/Seins";
import Visage from "./pages/Visage/Visage";
import LoginPage from "./pages/loginPage/login.page";
import AdminComponent from "./components/AdminComponent";
import DynamicPage from "./pages/DynamicPage/DynamicPage";
import BiographiePage from "./pages/biographie/biographie.page";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<PageContainer />}>
            <Route index element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/corps" element={<Corps />} />
            <Route path="/seins" element={<Seins />} />
            <Route path="/visage" element={<Visage />} />
            <Route path="/:slug" element={<DynamicPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/biographie" element={<BiographiePage />} />
          </Route>
          <Route
            path="/admin"
            element={<ProtectedRoute element={<AdminComponent />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
