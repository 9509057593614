import React from "react";
import ImageBannerAnimation from "./ImageBannerAnimation";

export interface CompositionContent {
  readonly title: string;
  readonly topImage: string;
  readonly bottomImage: string;
  readonly mainDescription: ReadonlyArray<{
    readonly text: string;
    readonly indent?: number;
  }>;
  readonly bottomText: {
    readonly above: string;
    readonly below: string;
  };
}

interface CompositionSectionProps {
  readonly content: CompositionContent;
  readonly iconColor: "white" | "black";
  readonly inputControl: "transparent" | "white";
  readonly light: boolean;
}

const MainDescription: React.FC<{
  items: CompositionContent["mainDescription"];
}> = ({ items }) => (
  <div className="flex flex-col gap-10 items-center">
    {items.map((item, index) => (
      <div
        key={index}
        className="flex flex-col lg:flex-row gap-5 justify-normal items-start w-full mt-4 lg:mt-8"
      >
        <div className="hidden lg:flex items-center justify-normal mt-3">
          <div
            className="rounded-full bg-lightB p-[3px] mr-[1rem]"
            style={{ marginLeft: item.indent ? `-${item.indent}rem` : "-8rem" }}
          ></div>
          <div className="border-b border-lightB w-[20rem]"></div>
        </div>
        <p className="font-textDescription leading-8 text-base sm:text-lg">
          {item.text}
        </p>
      </div>
    ))}
  </div>
);

const CompositionSection: React.FC<CompositionSectionProps> = ({
  content,
  // iconColor and inputControl included in props but not used directly
}) => {
  return (
    <section className="bg-darkBg text-lightB w-full grid place-items-center pt-24 sm:pt-24">
      <h2 className="text-center text-3xl md:text-6xl sm:text-6xl animate-slidein600 uppercase px-4 sm:px-0">
        {content.title}
      </h2>
      <div className="grid place-items-center gap-10 w-[90vw] sm:max-w-[70vw]">
        <div className="items-center flex flex-col lg:flex-row pt-12 sm:pt-24 w-full">
          {/* Top image */}
          <div className="flex-1 overflow-hidden block w-full px-4 lg:px-0">
            <div className="z-1 max-h-full h-full max-w-96">
              <ImageBannerAnimation imageUrl={content.topImage} />
            </div>
          </div>

          {/* Main description */}
          <div className="flex-1 relative h-auto lg:h-96 mt-12 lg:mt-0">
            <div className="lg:absolute lg:-left-56 w-full lg:w-[50vw] z-2 px-4 lg:px-0">
              <MainDescription items={content.mainDescription} />
            </div>
          </div>
        </div>

        {/* Bottom section */}
        <div className="justify-center items-center max-w-4xl w-full px-4 lg:px-0">
          <p className="font-textDescription pb-8 sm:pb-16 text-base sm:text-lg">
            {content.bottomText.above}
          </p>
          <div className="z-1 w-full sm:max-w-[48vw]">
            <ImageBannerAnimation imageUrl={content.bottomImage} />
          </div>
          <p className="font-textDescription py-8 sm:py-16 text-base sm:text-lg">
            {content.bottomText.below}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CompositionSection;
