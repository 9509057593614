import Divider from "@mui/material/Divider";
import { Facebook, Instagram } from "lucide-react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="text-center  w-full pb-12 bg-white">
      <div className="flex justify-center mb-12">
        <img
          src="/assets/images/logo noir horiz.png"
          alt="logo Dr. Youssef Gam"
          className="h-16 object-contain"
        />
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="py-12 sm:flex">
        <h5 className="text-2xl flex-auto sm:text-xl">TUNIS</h5>

        <h5 className="text-2xl flex-auto sm:text-xl">PARIS</h5>
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="py-12 sm:flex">
        <div className="flex-auto mb-8 sm:mb-0 ">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            CHIRURGIE ESTHÉTIQUE
          </h5>
          <Link to="visage" className="block text-lg sm:text-base pb-3">
            Visage
          </Link>
          <Link to="seins" className="block text-lg sm:text-base pb-3">
            Seins
          </Link>
          <Link to="corps" className="block text-lg sm:text-base">
            Corps
          </Link>
        </div>
        <div className="flex-auto mb-8 sm:mb-0 ">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            MÉDECINE ESTHÉTIQUE
          </h5>
          <Link to="/acide-hyaluronique">
            <span className="block text-lg sm:text-base pb-3">
              Acide Hyaluronique
            </span>
          </Link>
          <Link to="/botox">
            <span className="block text-lg sm:text-base pb-3">Botox</span>
          </Link>
          <Link to="/morpheus8">
            <span className="block text-lg sm:text-base pb-3">Morpheus 8</span>
          </Link>
          <Link to="/filler">
            <span className="block text-lg sm:text-base pb-3">
              Filler Visage Et Corps
            </span>
          </Link>
          <Link to="/threadLift">
            <span className="block text-lg sm:text-base pb-3">
              Files Tenseurs
            </span>
          </Link>
          <Link to="/hairTransplant">
            <span className="block text-lg sm:text-base pb-3">
              Greffe De Cheveux
            </span>
          </Link>
          <Link to="/sculptra">
            <span className="block text-lg sm:text-base pb-3">Scluptra</span>
          </Link>
          <Link to="/hydrafacial">
            <span className="block text-lg sm:text-base pb-3">Hydrafacial</span>
          </Link>
        </div>
        <div className="flex-auto">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            CABINET
          </h5>
          {/* <Link to="actualites" className="block text-lg sm:text-base pb-3">
            Actualités
          </Link> */}
          <Link to="/contact" className="block text-lg sm:text-base">
            Contact
          </Link>
        </div>
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="pt-12 sm:flex">
        <h5 className="text-l flex-auto sm:text-base mb-4 sm:mb-0">
          © 2025 DR. YOUSSEF GAM
        </h5>
        <div className="flex gap-5 justify-center mb-4 sm:mb-0">
          <Link to="https://www.facebook.com/youssef.gam.3" target="_blank">
            <Facebook strokeWidth={1} />
          </Link>
          <Link to="https://www.instagram.com/drgamyoussef/" target="_blank">
            <Instagram strokeWidth={1} />
          </Link>
        </div>
        <h5 className="text-l flex-auto sm:text-base">
          <Link to="https://www.codini.tn" target="_blank">
            CRÉATION: CODINI
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Footer;
