interface ContentItem {
    title: string;
    description: string;
}

interface SectionContent {
    mainDescription: string;
    content: ContentItem[];
}

interface CorpsData {
    [key: string]: SectionContent;
}

export const paragraphContent: CorpsData = {
        "Abdominoplastie": {
            mainDescription: "L'abdominoplastie est une intervention chirurgicale visant à enlever l'excès de peau et de graisse du ventre, et à resserrer les muscles abdominaux affaissés. Elle est idéale pour les personnes ayant perdu beaucoup de poids ou pour celles ayant des muscles abdominaux distendus après une grossesse.",
            content: [
                {
                    title: "Mode opératoire",
                    description: "La phase préopératoire comprend plusieurs consultations pour évaluer votre état général et déterminer les spécificités de l’intervention. Lors de la première consultation, un examen clinique détaillé sera réalisé, et des recommandations personnalisées vous seront données, notamment sur la gestion du poids avant l’opération. Pendant l’intervention, l’excès de peau situé entre le nombril et le pubis est retiré, et la peau restante est retendue vers le bas pour restaurer un aspect harmonieux. L’ombilic est repositionné à sa place naturelle pour un résultat esthétique optimal."
                },
                {
                    title: "Suites opératoires",
                    description: "Après l’opération, une fine cicatrice horizontale au niveau du pubis et une autour du nombril seront présentes mais discrètes et dissimulables sous les sous-vêtements. L’intervention, réalisée sous anesthésie générale, dure environ 1h30 à 2h. La patiente peut marcher dès le jour même et devra porter une gaine compressive en continu pendant deux semaines. Les douleurs sont modérées et bien gérées. Les activités comme le sport, les bains de mer, et l’exposition au soleil sont généralement possibles dès la troisième semaine. Les résultats définitifs, notamment l’aspect final des cicatrices, s’apprécient au bout d’un an."
                }
            ]
        },
    
    "Liposculpture HD avec Bodytite": {
    mainDescription: "La liposculpture HD avec Bodytite est une technique avancée qui combine la précision de la liposculpture avec la technologie de radiofréquence Bodytite. Elle permet de sculpter des contours corporels harmonieux tout en raffermissant les tissus, idéale pour les patients recherchant des résultats naturels et durables. Cette intervention minimalement invasive vise non seulement à éliminer les excès de graisse localisée, mais également à améliorer la texture et l’élasticité de la peau.",
    content: [
        { 
            title: "Bénéfices de la procédure", 
            description: "La liposculpture HD avec Bodytite est adaptée aux personnes cherchant un remodelage corporel précis et un raffermissement cutané simultané. La technologie Bodytite agit via la radiofréquence pour stimuler la production de collagène, offrant une peau plus ferme après l’élimination des graisses. Ce procédé est particulièrement efficace pour les zones telles que l’abdomen, les cuisses, et les bras, où une définition musculaire peut être mise en avant."
        },
        { 
            title: "Déroulement de l’intervention", 
            description: "L’intervention dure entre 1 et 3 heures selon les zones traitées, sous anesthésie locale ou générale selon les besoins. Le chirurgien utilise une canule fine pour aspirer les graisses tout en appliquant la radiofréquence pour chauffer et resserrer les tissus environnants. Un vêtement de contention est porté pendant 4 à 6 semaines pour optimiser les résultats et minimiser le gonflement. Les patients peuvent reprendre des activités légères sous 48 heures, tandis que le sport est recommandé après 4 à 6 semaines."
        }
    ]
},
    "Lipoaspiration": {
        mainDescription: "De nombreuses parties du corps sont concernées par la lipoaspiration qui va permettre d'éradiquer des surcharges de graisse génétiquement programmées : double menton, bras, mollets, hanches, cuisses, genoux, culotte de cheval …",
        content: [
            { 
                title: "Mode opératoire", 
                description: "Les cellules graisseuses (adipocytes) en surnombre vont être aspirées à l'aide d'une canule mousse qui sera introduite à travers de petites incisions situées discrètement dans un pli naturel. La qualité de la peau va déterminer la quantité de graisse extraite et la réussite de l'intervention.Dans la mesure où ces cellules ne peuvent se multiplier, il n'y aura pas de récidive de cet excédent d'adipocytes.On pratiquera sous anesthésie locale associée à une légère sédation mais aussi parfois sous anesthésie générale, en ambulatoire.La durée du geste opératoire variera en fonction de la quantité de graisse à extraire et du nombre de zones anatomiques. Elle varie entre 30 minutes et 2 heures." 
            },
            { 
                title: "Suites opératoires", 
                description: "L'inconfort reste généralement très supportable, associé souvent à de simples calmants.On attendra en moyenne 3 semaines pour reprendre le sport ou pour s'exposer au soleil.C'est d'ailleurs au bout de 3 semaines que le résultat commence à apparaître, après résorption de l'œdème. Il faudra 3 à 6 mois à la peau pour se rétracter et se réadapter à la nouvelle silhouette. Certaines irrégularités de la peau peuvent persister après la lipoaspiration. La lipoaspiration ne traite pas l'aspect de peau d'orange de la cellulite.*Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" 
            }
        ]
    },
    "Cruroplastie": {
    mainDescription: "La cruroplastie est une intervention chirurgicale qui consiste à raffermir et à redéfinir la silhouette des cuisses, particulièrement dans la région interne et supérieure. Cette procédure est idéale pour les patients souffrant d'un excès de peau ou de graisse dans cette zone, souvent causée par la génétique, les fluctuations de poids ou l'âge. La cruroplastie permet d'obtenir des jambes plus lisses et plus fermes, offrant une silhouette plus harmonieuse et rajeunie.",
    content: [
        { 
            title: "Mode opératoire", 
            description: "Il s'agit d'un geste sous anesthésie générale et en ambulatoire qui dure environ une heure et demie à deux heures. Une lipoaspiration est souvent pratiquée dans le même temps opératoire." 
        },
        { 
            title: "Suites opératoires", 
            description: "Les suites sont plus gênantes que douloureuses. En effet, la patiente sortira du bloc opératoire avec un pansement compressif qu'elle devra garder jusqu'au rendez-vous suivant, soit 4 ou 5 jours.Les douleurs sont très supportables avec parfois des hématomes peu importants, un léger gonflement ou encore des sensations de tiraillement. Il conviendra pendant cette période d'éviter tout mouvement d'étirement brutal, comme par exemple lever les bras pour ne pas tirer sur les sutures. Le sport peut être repris dès la 4ème semaine après l'intervention et la cicatrice sera protégée du soleil pendant 3 mois. Le résultat final s'appréciera entre 6 à 12 mois après l'opération, l'amélioration de la morphologie du bras étant souvent spectaculaire.*Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" 
        }
        ]
    },
        "Brachioplastie": {
            mainDescription: "La brachioplastie est une intervention chirurgicale esthétique qui vise à remodeler et raffermir la partie supérieure des bras. Cette procédure est particulièrement indiquée pour les patients présentant un relâchement cutané important au niveau des bras, souvent consécutif à une perte de poids significative, au vieillissement naturel ou à des facteurs génétiques. L'opération permet d'éliminer l'excès de peau et de graisse, redonnant ainsi aux bras une apparence plus tonique et harmonieuse.",
            content: [
                {
                    title: "Mode opératoire",
                    description: "L'intervention se déroule sous anesthésie générale et dure entre 2 et 3 heures selon l'importance de la correction à effectuer. Une incision est réalisée sur la face interne du bras, allant généralement de l'aisselle jusqu'au coude. Cette technique permet au chirurgien de retirer l'excès de peau et de graisse, puis de retendre les tissus. Une liposuccion peut être associée pour optimiser le résultat."
                },
                {
                    title: "Suites opératoires",
                    description: "Les suites opératoires nécessitent généralement une hospitalisation de 24 à 48 heures. Le patient doit porter un vêtement compressif pendant environ 4 à 6 semaines pour réduire l'œdème et favoriser la rétraction cutanée. Des douleurs modérées, des ecchymoses et un œdème sont à prévoir durant les premières semaines. La reprise des activités quotidiennes légères est possible après 2 semaines, mais les activités sportives doivent être évitées pendant 6 semaines. La cicatrice, initialement rouge, s'estompe progressivement sur 12 à 18 mois. Le résultat définitif est visible après 6 à 12 mois, lorsque les tissus se sont complètement repositionnés et que l'œdème a totalement disparu."
                }
            ]
    },
        "BodyLift": {
            mainDescription: "Le body lift, ou plastie corporelle circulaire, est une intervention chirurgicale complète qui vise à remodeler l'ensemble du tronc après une perte de poids massive. Cette procédure combine plusieurs techniques pour traiter simultanément l'abdomen, les flancs, les fesses et les cuisses. Elle est particulièrement indiquée pour les patients ayant subi une chirurgie bariatrique ou une perte de poids importante, permettant d'éliminer l'excès de peau résiduel et de restaurer des contours corporels plus harmonieux.",
            content: [
                {
                    title: "Mode opératoire",
                    description: "L'intervention se déroule sous anesthésie générale et nécessite une hospitalisation de 2 à 4 jours. L'opération dure entre 4 et 6 heures selon l'étendue des corrections à effectuer. Le chirurgien réalise une incision circonférentielle autour de la taille, permettant de redraper la peau de l'abdomen, des hanches, des fesses et des cuisses. Cette technique peut être combinée avec une lipoaspiration pour optimiser le résultat. La cicatrice résultante forme une ceinture qui peut être dissimulée sous les sous-vêtements."
                },
                {
                    "title": "Suites opératoires",
                    "description": "La récupération post-opératoire est significative et nécessite une période de repos de 3 à 4 semaines. Le patient doit porter une gaine de contention spéciale 24h/24 pendant 6 à 8 semaines pour réduire l'œdème et optimiser la rétraction cutanée. Les drains sont généralement retirés après 5 à 10 jours. Les activités quotidiennes légères peuvent être reprises progressivement après 3 semaines, mais les activités sportives doivent être évitées pendant 2 mois. Des douleurs modérées, des ecchymoses et un œdème important sont à prévoir durant le premier mois. Le résultat définitif s'apprécie après 6 à 12 mois, lorsque les tissus se sont complètement repositionnés et que les cicatrices se sont estompées."
                },
                {
                    title: "Précautions particulières",
                    description: "Cette intervention majeure nécessite une préparation minutieuse. Le poids du patient doit être stable depuis au moins 6 mois, et l'IMC ne doit idéalement pas dépasser 30. Un bilan préopératoire complet est obligatoire, incluant des examens sanguins et cardiovasculaires. Le tabac doit être arrêté au moins 6 semaines avant l'intervention pour optimiser la cicatrisation et réduire les risques de complications. Une surveillance médicale étroite est nécessaire pendant les premières semaines post-opératoires pour prévenir les complications potentielles comme les séromes ou les problèmes de cicatrisation."
                }
            ]
        },
            "Brazilian Butt Lift": {
            "mainDescription": "Le Brazilian Butt Lift (BBL) est une procédure de chirurgie esthétique qui combine une liposuccion et un transfert de graisse pour remodeler et augmenter le volume des fesses. Cette technique utilise la propre graisse du patient, prélevée dans des zones où elle est excédentaire (ventre, hanches, cuisses) pour être réinjectée de manière stratégique dans les fesses, permettant ainsi d'obtenir un résultat naturel et harmonieux tout en affinant la silhouette globale.",
            content: [
            {
            title: "Mode opératoire",
            description: "L'intervention se déroule sous anesthésie générale et dure entre 2 et 4 heures. La première étape consiste en une liposuccion des zones donneuses pour récolter la graisse. Cette graisse est ensuite purifiée et traitée pour ne conserver que les cellules adipeuses les plus viables. La réinjection se fait de manière précise à différents niveaux de profondeur dans les fesses, en respectant l'anatomie vasculaire pour maximiser la survie des cellules graisseuses. Un surtraitement de 20 à 30% est généralement nécessaire pour compenser la résorption naturelle d'une partie de la graisse transférée."
            },
            {
            title: "Suites opératoires",
            description: "La période post-opératoire requiert une attention particulière. Le patient doit éviter toute pression directe sur les fesses pendant 2 à 3 semaines, ce qui implique de dormir sur le ventre et d'utiliser un coussin spécial pour s'asseoir. Une gaine de contention doit être portée pendant 6 semaines sur les zones de liposuccion. Des ecchymoses et un œdème sont normaux pendant les premières semaines. Le résultat final n'est visible qu'après 3 à 6 mois, une fois que la graisse transférée s'est stabilisée et que l'œdème a complètement disparu."
            },
            {
            title: "Précautions spécifiques",
            description: "Cette intervention nécessite une expertise particulière du chirurgien en raison des risques potentiels, notamment d'embolie graisseuse si l'injection est trop profonde. La sélection des patients est cruciale : ils doivent disposer de suffisamment de zones donneuses de graisse et avoir des attentes réalistes. Le maintien d'un poids stable est essentiel après l'intervention, car des variations importantes peuvent affecter le résultat. Le tabac doit être arrêté au moins un mois avant l'intervention pour optimiser la survie des greffons graisseux. Un suivi régulier est nécessaire pendant les premiers mois pour surveiller l'évolution de la graffe."
            }
            ]
            }
};