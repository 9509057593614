import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactSchema } from "../validations/contact.validation";
import { Alert } from "@mui/material";

export function ContactForm() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactSchema),
  });

  const consent = watch("consent", false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch("/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setOpen(true);
        setSeverity("success");
        setMessage("Message envoyé avec succès");
      }
    } catch (error) {
      setSeverity("error");
      setOpen(true);
      setMessage("Message non envoyé");
      console.error(error);
    }
    reset();
  };

  const parallaxOffset = Math.min(scrollPosition * 0.4, 500); // Cap the parallax effect

  return (
    <div className="min-h-screen mb-28">
      {/* Hero Section */}
      <div className="relative h-[80vh] md:h-[80vh] w-full !text-[#ffffff]">
        <div
          style={{
            transform: `translate3d(0, ${-parallaxOffset}px, 0)`,
            willChange: "transform",
          }}
          className="absolute inset-0  sm:h-[80vh] h-[80vh] bg-cover bg-no-repeat   bg-top w-full  active"
        >
          <img
            src="/assets/images/dr-youssef-gam.jpeg"
            alt="Dr. Youssef Gam operating"
            className="w-full h-full  bg-contain object-cover bg-no-repeat bg-fixed "
          />
          <div className="absolute inset-0  bg-darkB opacity-30 "></div>
        </div>
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-4">
          <h1 className="!text-white text-4xl md:text-6xl mb-6">COORDONNÉES</h1>
          <div className="space-y-4 text-center">
            <div className="flex flex-col items-center space-y-2">
              <div className="w-12 h-12 border border-white !text-[#ffffff] rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="21"
                  fill="white"
                  viewBox="0 0 13 21"
                  className="fill-current "
                >
                  <path d="M13 6.322C13 2.83 10.09 0 6.5 0S0 2.83 0 6.322c0 3.493 2.91 6.322 6.5 6.322V21l5.818-11.848A6.276 6.276 0 0013 6.322z"></path>
                </svg>
              </div>
              <p className="text-lg md:text-2xl font-textDescription">
                contact@dryoussefgam.com
              </p>
            </div>

            <div className="flex flex-col items-center space-y-2">
              <div className="w-12 h-12 border border-white rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  viewBox="0 0 20 20"
                  className="fill-current"
                >
                  <path d="M19.386 15.75a2.108 2.108 0 01-.522 3.36 7.543 7.543 0 01-5.476.642c-4.886-1.23-11.91-8.253-13.14-13.14a7.543 7.543 0 01.643-5.475A2.108 2.108 0 014.25.615l2.085 2.084c.615.61.792 1.535.445 2.328-.186.435-.45.832-.778 1.172-2.384 2.384 5.417 10.185 7.8 7.8.34-.33.737-.595 1.173-.781a2.092 2.092 0 012.328.445z"></path>
                </svg>
              </div>
              <p className="text-lg md:text-2xl font-textDescription">
                +216 55 725 165
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <h2 className="text-3xl md:text-5xl mb-8">ENVOYEZ-NOUS UN MESSAGE</h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <input
                placeholder="Nom*"
                type="text"
                {...register("name")}
                className="w-full border-b text-xl md:text-3xl border-gray-300 focus:border-black outline-none placeholder-black py-2"
              />
              {errors.name && (
                <span className="text-red-500">{errors.name.message}</span>
              )}
            </div>

            <div>
              <input
                placeholder="Prenom*"
                type="text"
                {...register("prenom")}
                className="w-full text-xl md:text-3xl border-b border-gray-300 focus:border-black outline-none placeholder-black py-2"
              />
              {errors.prenom && (
                <span className="text-red-500">{errors.prenom.message}</span>
              )}
            </div>
          </div>

          <div>
            <input
              placeholder="Email*"
              type="text"
              {...register("email")}
              className="w-full text-xl md:text-3xl border-b border-gray-300 focus:border-black outline-none placeholder-black py-2"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>

          <div>
            <input
              placeholder="Telephone*"
              type="text"
              {...register("phoneNumber")}
              className="w-full text-xl md:text-3xl border-b border-gray-300 focus:border-black outline-none placeholder-black py-2"
            />
            {errors.phoneNumber && (
              <span className="text-red-500">{errors.phoneNumber.message}</span>
            )}
          </div>

          <div>
            <input
              placeholder="Sujet*"
              type="text"
              {...register("sujet")}
              className="w-full text-xl md:text-3xl border-b border-gray-300 focus:border-black outline-none placeholder-black py-2"
            />
            {errors.sujet && (
              <span className="text-red-500">{errors.sujet.message}</span>
            )}
          </div>

          <div>
            <textarea
              placeholder="Message*"
              {...register("message")}
              className="w-full text-xl md:text-3xl placeholder-black py-4 px-4 h-32 bg-transparent border border-black"
            />
            {errors.message && (
              <span className="text-red-500">{errors.message.message}</span>
            )}
          </div>

          <div className="flex items-start space-x-2">
            <input type="checkbox" {...register("consent")} className="mt-1" />
            <label className="text-sm md:text-base">
              En cochant cette case, j'affirme avoir pris connaissance de la
              politique de confidentialité*
            </label>
          </div>
          {errors.consent && (
            <span className="text-red-500">{errors.consent.message}</span>
          )}

          <p className="text-sm">*Champs obligatoires</p>

          <button
            type="submit"
            className={`w-full md:w-auto py-3 text-xl md:text-2xl transition-colors duration-300 ${
              consent ? "text-black" : "text-gray-400"
            } underline`}
          >
            ENVOYER
          </button>
        </form>

        {open && (
          <Alert
            variant="filled"
            className={`mt-4 ${
              severity === "success" ? "bg-green-500" : "bg-red-500"
            } text-white`}
          >
            {message}
          </Alert>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
