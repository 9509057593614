import "../styles.css";
import { useRef } from "react";
import { motion, useMotionValue, useAnimationFrame, useTransform } from "framer-motion";
import { wrap } from "@motionone/utils";

interface ParallaxProps {
    children: string;
    baseVelocity: number;
}

function ParallaxText({ children, baseVelocity = 100 }: ParallaxProps) {
    const baseX = useMotionValue(0);

    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef<number>(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 3300);

        baseX.set(baseX.get() - moveBy);
    });

    return (
        <div className="parallax">
            <motion.div className="scroller" style={{ x }}>
                <span className="text-black text-[1.7rem]">{children} </span>
                <span className="text-black text-[1.7rem]">{children} </span>
                <span className="text-black text-[1.7rem]">{children} </span>
                <span className="text-black text-[1.7rem]">{children} </span>
            </motion.div>
        </div>
    );
}


export default function FramerReverse() {
    return (
        <section>

            <div >
                <ParallaxText baseVelocity={5}>GREFFE DE CHEVEUX MORPHEUS8 FILLER BOTOX SCULPTRA HYDRAFACIAL  </ParallaxText>

            </div>
        </section>
    );
}
