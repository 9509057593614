import React, { useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import {
  motion,
  useScroll,
  useTransform,
  useMotionTemplate,
} from "framer-motion";
import { motion as motion3d } from "framer-motion-3d";
import Lenis from "@studio-freight/lenis";

interface ProjectData {
  title: string;
  speed: number;
  i?: number;
}

const projectsData: ProjectData[] = [
  { title: "Patients", speed: 0.8 },
  { title: "Du", speed: 0.8 },
  { title: "Monde", speed: 0.67 },
  { title: "Tunis", speed: 0.7 },
  { title: "Paris", speed: 0.7 },
];

const Earth = () => {
  const scene = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scene,
    offset: ["start end", "end start"],
  });

  const [color, normal, aoMap] = useLoader(TextureLoader, [
    "/assets/color.jpg",
    "/assets/normal.png",
    "/assets/occlusion.jpg",
  ]);

  return (
    <div ref={scene} className="h-screen w-full absolute inset-0 sm:py-0 py-24">
      <Canvas>
        <ambientLight intensity={0.1} />
        <directionalLight intensity={3.5} position={[1, 0, -0.25]} />
        <motion3d.mesh scale={2.5} rotation-y={scrollYProgress}>
          <sphereGeometry args={[1, 64, 64]} />
          <meshStandardMaterial map={color} normalMap={normal} aoMap={aoMap} />
        </motion3d.mesh>
      </Canvas>
    </div>
  );
};

const Title: React.FC<{ data: ProjectData }> = ({ data }) => {
  const { title, speed, i } = data;
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", `${25 / speed}vw end`],
  });

  const clipProgress = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const clip = useMotionTemplate`inset(0 ${clipProgress}% 0 0)`;

  return (
    <div ref={container} className="border-b border-[rgba(183,171,152,0.25)]">
      <div className="inline-block pl-[10%]">
        <div className="relative">
          <motion.p
            style={{ clipPath: clip }}
            className="inline-block text-[#b7ab98] uppercase font-bold text-[10vw] sm:py-0 py-8 sm:text-[7vw] leading-[7.5vw] m-0 relative z-20"
          >
            {title}
          </motion.p>
          <p className="block absolute top-0 text-[#1c1c1c] uppercase font-bold text-[10vw] sm:py-0 py-8 sm:text-[7vw] leading-[7.5vw] m-0 z-10">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};

const Projects: React.FC = () => {
  return (
    <div className="absolute -mt-12 z-10 w-full h-full flex flex-col justify-center border-t border-[rgba(183,171,152,0.25)]">
      {projectsData.map((project, i) => (
        <Title key={i} data={{ ...project, i }} />
      ))}
    </div>
  );
};

const EarthScrollSection: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const lenis = new Lenis();

    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <main className="relative w-full min-h-screen bg-darkBg overflow-hidden">
      <Earth />
      <Projects />
    </main>
  );
};

export default EarthScrollSection;
