import React from "react";
import { useParams } from "react-router-dom";
import { pageData, PageSlug } from "../../data/medicineEsthetiqueData";
import DynamicComponent from "../../components/DynamicComponent";

const DynamicPage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const pageDataContent = pageData[slug as PageSlug] || pageData["acide-hyaluronique"];


    return <DynamicComponent {...pageDataContent} />;
};

export default DynamicPage;