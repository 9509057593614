import { motion } from "framer-motion";

const IgniteRF = () => {
  return (
    <section className="relative w-full h-screen overflow-hidden">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="/assets/videos/IgniteRF-Banner.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-darkB/30 to-transparent" />

      {/* Content Container */}
      <div className="relative z-10 container mx-auto px-4 h-screen flex items-center text-lightB">
        <div className="max-w-3xl">
          <motion.h1
            className="text-5xl font-bold text-white mb-6"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
              ease: [0.16, 1, 0.3, 1], // Custom bezier curve for a classy feel
              delay: 0.2,
            }}
          >
            Technologie IgniteRF
          </motion.h1>

          <motion.div
            className="prose prose-lg text-white "
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 1,
              ease: [0.16, 1, 0.3, 1],
              delay: 0.5,
            }}
          >
            <p className="text-xl mb-4 font-bold">
              Découvrez l'avenir des traitements esthétiques avec IgniteRF,
              notre technologie de pointe de microneedling par radiofréquence.
            </p>

            <motion.p
              className="text-lg opacity-90 font-bold"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 0.9 }}
              transition={{
                duration: 1,
                ease: [0.16, 1, 0.3, 1],
                delay: 0.7,
              }}
            >
              IgniteRF associe une micro-aiguille précise à une énergie
              radiofréquence ciblée pour stimuler la production de collagène,
              raffermir la peau et réduire l'apparence des rides et ridules. Ce
              traitement révolutionnaire offre un temps d'arrêt minimal avec des
              résultats optimaux, parfait pour ceux qui recherchent un
              rajeunissement d'apparence naturelle.
            </motion.p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default IgniteRF;
