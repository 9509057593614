import { ComponentType } from "react";
import "./transition.css";

const PageTransition = ({ OgComponent }: { OgComponent?: ComponentType }) => {
  return (
    <div className="fixed bg-transparent top-0 left-0 w-dvw h-dvh z-50">
      <div className="relative w-full h-full grid place-items-center">
        <div className="bg-darkBg h-full w-full to-top"></div>
        <div className="absolute top-1/2  transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center z-50 to-top-text">
          <div className="text-4xl text-lightB">
            Dr. Youssef Gam
          </div>
          <img
            src="/assets/images/symbole blanc.png"
            className="h-12 sm:ml-6 ml-2 "
            alt="Dr. Youssef Gam logo"
          />
        </div>
      </div>
    </div>
  );
};

export default PageTransition;