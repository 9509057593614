import React, { useEffect, useState, useRef } from "react";
import ImageBannerAnimation from "./ImageBannerAnimation";

export interface MedecineEsthetiqueContent {
  readonly title: string;
  readonly subtitle: string;
  readonly description: string;
  readonly presentationImage: string;
  readonly presentationTitle: string;
  readonly presentationText: string;
  readonly backgroundImage: string;
}

interface MedecineEsthetiqueSectionProps {
  readonly content: MedecineEsthetiqueContent;
  readonly iconColor: "white" | "black";
  readonly inputControl: "transparent" | "white";
  readonly light: boolean;
}

const Header: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => (
  <>
    <h2 className="text-3xl md:text-4xl lg:text-6xl pt-24 sm:pt-52 pb-5 text-lightB animate-slidein600 px-4 sm:px-0">
      {title}
    </h2>
    <h2 className="text-3xl md:text-4xl lg:text-6xl text-lightB pb-8 sm:pb-12 mb-[2vh] animate-slidein600 px-4 sm:px-0">
      {subtitle}
    </h2>
  </>
);

const Description: React.FC<{ text: string }> = ({ text }) => (
  <div className="w-11/12 sm:w-9/12 px-4 sm:px-0">
    <p className="text-lg sm:text-xl text-lightB text-center font-textDescription">
      {text}
    </p>
  </div>
);

const PresentationSection = React.forwardRef<
  HTMLDivElement,
  {
    isVisible: boolean;
    image: string;
    title: string;
    text: string;
  }
>(({ isVisible, image, title, text }, ref) => (
  <div
    ref={ref}
    className={`flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-16 px-4 sm:px-8 lg:px-32 pt-12 text-container transition-opacity duration-500 ${
      isVisible ? "animate-slidein600 opacity-100" : "opacity-0"
    }`}
  >
    <div className="h-full overflow-hidden border-solid border-4 sm:border-8 border-lightB flex-1">
      <ImageBannerAnimation imageUrl={image} />
    </div>
    <div className="flex-1">
      <h2 className="text-4xl sm:text-5xl lg:text-6xl text-lightB pb-6 sm:pb-8 text-left">
        {title}
      </h2>
      <p className="text-lg sm:text-xl lg:text-2xl text-lightB flex-auto first-letter:text-6xl sm:first-letter:text-9xl first-letter:font-primaryLight first-letter:mr-3 first-letter:float-left text-left font-textDescription">
        {text}
      </p>
    </div>
  </div>
));

PresentationSection.displayName = "PresentationSection";

const MedecineEsthetiqueSection: React.FC<MedecineEsthetiqueSectionProps> = ({
  content,
  light,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = textContainerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      className="min-h-[180vh] bg-cover bg-no-repeat bg-fixed bg-bottom w-full bg-parallax active relative"
      style={{ backgroundImage: `url(${content.backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-darkB/20" />
      <div className="z-10 relative flex flex-col justify-center items-center text-center">
        <Header title={content.title} subtitle={content.subtitle} />
        <Description text={content.description} />
        <PresentationSection
          ref={textContainerRef}
          isVisible={isVisible}
          image={content.presentationImage}
          title={content.presentationTitle}
          text={content.presentationText}
        />
      </div>
    </section>
  );
};

export default MedecineEsthetiqueSection;
