import LoginComponent from "../../components/LoginComponent";

const LoginPage: React.FC = () => {
  return (
    <div className="page-animation">
      <LoginComponent />
    </div>
  );
};

export default LoginPage;
