import { useState } from "react";
import { axiosInstance } from "../constants";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const LoginComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axiosInstance.post("/login", {
        username: username,
        password: password,
      });
  
      if (response.data.success) {
        const token = response.data.token;
        login(token); // AuthProvider handles localStorage here
        navigate("/admin");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed");
    }
  };
  
  return (
    <div className="flex justify-center items-center h-screen">
      <div className=" shadow-xl rounded px-8 pt-6 pb-8 mb-4 w-1/3">
        <h2 className="text-2xl mb-6 text-center">Admin Login</h2>
        {error && (
          <div className=" border-l-4 p-4 mb-4" role="alert">
            <p>{error}</p>
          </div>
        )}
        <div className="mb-4">
          <label className="block  text-sm font-bold mb-2">Username</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight "
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block  text-sm font-bold mb-2">Password</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight "
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            className=" font-bold py-2 px-4 rounded "
            type="button"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
