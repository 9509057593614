import React, { useEffect, useState } from "react";
import SurgerySection from "./SurgerySection";

const HeroSection: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition(window.scrollY);
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const parallaxOffset = Math.min(scrollPosition * 0.4, 500); // Cap the parallax effect

  return (
    <section>
      <div
        style={{
          transform: `translate3d(0, ${-parallaxOffset}px, 0)`,
          willChange: "transform",
        }}
        className={`absolute inset-0 bg-[url('./assets/img/hero-bg.jpeg')] sm:h-[200vh] h-[200vh] bg-cover bg-no-repeat bg-center w-full active`}
      >
        <div className="absolute inset-0 bg-darkB opacity-30 "></div>
      </div>

      <div className="z-10 relative flex flex-col justify-center items-center text-center">
        <h2 className="sm:text-5xl text-3xl sm:pt-52 pt-32 pb-5 text-lightB animate-slidein600 ">
          CHIRURGIEN PLASTIQUE ET ESTHÉTIQUE
        </h2>
        <h2 className="sm:text-3xl sm:max-w-[40dvw] max-w-[70dvw] text-center font-bold text-3xl pt-10 pb-5 text-lightB animate-slidein600 ">
          EXPERTISE MÉDICALE ALIGNÉE SUR LES STANDARDS INTERNATIONAUX
        </h2>
        {/* <div className="w-full">
          <GptInputField />
        </div> */}
        {/* <div className={`mb-[27vh] ${buttonVisible ? "animate-slidein600" : "opacity-0"} text-xl text-lightB`}>
          <Link to={"/contact"}>
            <button className="white_button">Contactez-Nous</button>
          </Link>
        </div> */}
        {/* <div
          className={`sm:flex sm:px-[4rem] px-6  sm:pt-20 sm:text-container transition-opacity duration-500 ${"animate-slidein600 opacity-100"}`}
        >
          <p className="text-lightB sm:text-5xl sm:flex-auto pr-24 text-left text-4xl sm:mb-24 mb-5 sm:text-left">
            BIOGRAPHIE
          </p>
          <div className="text-2xl mb-12 text-lightB  first-letter:text-8xl first-letter:font-primaryLight first-letter:mr-3 first-letter:float-left text-left font-textDescription">
            <span className="pr-5">
              Le Dr Youssef Gam est un chirurgien plasticien, fort d’une
              carrière riche et variée en Tunisie, en France. Ancien praticien
              attaché des Hôpitaux de Paris, il a exercé dans des cliniques
              privées prestigieuses à Paris et en Tunisie, reconnues pour leur
              exigence en termes d’innovation et de qualité des soins.
            </span>
            <span className="white_button font-bold text-xl">
              <Link to="/biographie">Lire plus</Link>
            </span>
          </div>
        </div> */}

        <div>
          <h2 className="sm:text-6xl text-4xl sm:mt-[40vh] mt-60 text-lightB sm:pb-24 pb-16 px-6">
            CHIRURGIE ESTHÉTIQUE
          </h2>
        </div>

        <div className="mb-[10vh]">
          <SurgerySection />
        </div>
      </div>
      <div className="text-center justify-center"></div>
    </section>
  );
};

export default HeroSection;
