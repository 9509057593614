import React from "react";
import { motion } from "framer-motion";

const DualVideoSection = () => {
  const contentVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  };

  const descriptionVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  };

  return (
    <section className="relative w-full md:h-screen flex-col md:flex-row flex">
      {/* First Video Section */}
      <div className="relative w-full md:w-1/2 h-screen overflow-hidden">
        {/* Video Background */}
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="/assets/videos/Vibrofit.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Gradient Overlay */}
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-darkB/60 to-transparent" />

        {/* Content Container */}
        <div className="relative z-10 px-4 md:px-8 h-full flex items-center text-lightB">
          <div className="max-w-xl">
            <motion.h2
              className="text-3xl md:text-4xl font-bold text-white mb-6"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              variants={contentVariants}
            >
              Technologie Vibrofit
            </motion.h2>
            <motion.div
              className="prose prose-lg text-white"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              variants={descriptionVariants}
            >
              <p className="text-lg md:text-xl mb-4 font-bold">
                Découvrez le traitement révolutionnaire Vibrofit qui transforme
                votre corps.
              </p>
              <motion.p
                className="text-base md:text-lg opacity-90 font-bold"
                initial={{ y: 30, opacity: 0 }}
                whileInView={{ y: 0, opacity: 0.9 }}
                viewport={{ once: false }}
                transition={{
                  duration: 1,
                  ease: [0.16, 1, 0.3, 1],
                }}
              >
                Notre technologie de pointe Vibrofit combine des vibrations
                ciblées avec des techniques avancées de remodelage du corps pour
                des résultats optimaux avec un temps d'arrêt minimal.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Second Video Section */}
      <div className="relative w-full md:w-1/2 h-screen overflow-hidden">
        {/* Video Background */}
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="/assets/videos/vaser.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Gradient Overlay */}
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-darkB/60 to-transparent" />

        {/* Content Container */}
        <div className="relative z-10 px-4 md:px-8 h-full flex items-center text-lightB">
          <div className="max-w-xl">
            <motion.h2
              className="text-3xl md:text-4xl font-bold text-white mb-6"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              variants={contentVariants}
            >
              Technologie Vaser
            </motion.h2>
            <motion.div
              className="prose prose-lg text-white"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              variants={descriptionVariants}
            >
              <p className="text-lg md:text-xl mb-4 font-bold">
                Découvrez la nouvelle génération de modelage corporel avec
                Vaser.
              </p>
              <motion.p
                className="text-base md:text-lg opacity-90 font-bold"
                initial={{ y: 30, opacity: 0 }}
                whileInView={{ y: 0, opacity: 0.9 }}
                viewport={{ once: false }}
                transition={{
                  duration: 1,
                  ease: [0.16, 1, 0.3, 1],
                }}
              >
                La technologie Vaser fournit une énergie ultrasonique précise
                pour cibler sélectivement les graisses indésirables tout en
                préservant les tissus environnants, offrant des résultats lisses
                et naturels.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DualVideoSection;
