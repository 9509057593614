export interface ContentListItem {
  title: string;
  description: string;
}

export interface MedecineEsthetique {
  title: string;
  subtitle: string;
  description: string;
  presentationImage: string;
  presentationTitle: string;
  presentationText: string;
  backgroundImage: string;
}

export interface MainDescriptionItem {
  text: string;
  indent: number;
}

export interface Composition {
  title: string;
  topImage: string;
  bottomImage: string;
  mainDescription: MainDescriptionItem[];
  bottomText: {
    above: string;
    below: string;
  };
}

export interface PageData {
  contentList: ReadonlyArray<ContentListItem>;
  light: boolean;
  iconColor?: "white" | "black";
  inputControl?: "transparent" | "white";
  medecineEsthetique?: MedecineEsthetique;
  composition?: Composition;
  sections?: ReadonlyArray<
    "medecineEsthetique" | "composition" | "ceQuilFautSavoir" | "questions"
  >;
}

export const pageData: Record<string, PageData> = {
  "acide-hyaluronique": {
    contentList: [
      {
        title: "Comment la toxine botulique fonctionne-t-elle ?",
        description:
          "La toxine botulique agit au niveau de l'union entre le nerf...",
      },
      {
        title: "Injections de Toxine Botulique - LE BOTOX ®",
        description:
          "Liquide, la toxine botulique est injectée avec des aiguilles très fines...",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "INJECTION ACIDE HYALURONIQUE",
      subtitle: "",
      description:
        "Avec le temps, la peau se déshydrate, les fibres de collagène et d'élastine diminuent et perdent leur structure. Tout ceci entraîne une perte de volume cutané. C'est alors que se forment rides et plis du visage. Le vieillissement facial est également causé par d'autres agents comme les changements hormonaux de la ménopause, l'irradiation aux rayons UV du soleil et bien sûr le tabac.",
      presentationImage:
        "https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-medecine-intro-1246x1408.jpg",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Les injections d'acide hyaluronique (AH) sont une solution efficace pour pallier ce vieillissement du visage. Elles permettent d'effacer les rides et contrairement aux injections de toxine botulique, elles ne modifient pas la mobilité des muscles faciaux ; ainsi les expressions du visage ne sont pas altérées.",
      backgroundImage:
        "https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-hyaloroic-acid.jpg",
    },
    composition: {
      title: "COMPOSITION",
      topImage:
        "https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-composition-top.jpg",
      bottomImage:
        "https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-composition-bottom.jpg",
      mainDescription: [
        {
          text: "L'acide hyaluronique est la molécule idéale pour le comblement des rides car composée de sucre; il est naturellement présent dans l'épiderme. Avec le temps, la quantité d'acide hyaluronique diminue et la peau se déshydrate. Cette diminution est l'un des facteurs du vieillissement cutané.",
          indent: 8,
        },
        {
          text: "L'acide hyaluronique est d'origine non-animale",
          indent: 8,
        },
        {
          text: "L'acide hyaluronique est biodégradable et résorbable.\nSa dégradation par les hyaluronidase naturelles est progressive.",
          indent: 3,
        },
      ],
      bottomText: {
        above:
          "L'acide hyaluronique est disponible dans de nombreuses fluidités qui permettent de restaurer les volumes du visage mais également de combler tous les types de rides du visage, qu'elles soient fines ou profondes.",
        below:
          "Il contient de l'anesthésique local qui permet de rendre l'injection indolore",
      },
    },
    sections: ["medecineEsthetique", "composition", "ceQuilFautSavoir"],
  },
  botox: {
    contentList: [
      {
        title: "Comment fonctionne la toxine botulique ?",
        description:
          "La toxine botulique agit au niveau de la jonction entre le nerf et le muscle. Elle bloque la transmission de l'influx nerveux vers le muscle, entraînant une relaxation musculaire temporaire. Cette action permet de réduire les rides d'expression tout en préservant la mobilité naturelle du visage.",
      },
      {
        title: "Injections de Toxine Botulique - BOTOX ®",
        description:
          "Sous forme liquide, la toxine botulique est injectée avec des aiguilles très fines directement dans les muscles ciblés. Le traitement est rapide (15-20 minutes), peu douloureux et ne nécessite pas de temps de récupération. Les effets apparaissent progressivement sur 3-5 jours et atteignent leur maximum après 15 jours.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "BOTULINUM TOXIN INJECTIONS BOTOX ®",
      subtitle: "",
      description:
        "Le Botox est un traitement esthétique non chirurgical utilisé pour lisser les rides d'expression, prévenir les signes du vieillissement et offrir un aspect reposé et rajeuni. Rapide et efficace, il cible les muscles responsables des rides, pour des résultats naturels et subtils.",
      presentationImage: "/assets/images/botox2.png",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Le Botox® est une protéine purifiée qui, une fois injectée en petites doses dans un muscle, bloque la transmission entre le nerf et le muscle, entraînant une relaxation musculaire temporaire. Cette action permet de lisser les rides d'expression tout en préservant une mobilité naturelle du visage. Le traitement est rapide, peu douloureux et ne nécessite pas de temps de récupération.",
      backgroundImage: "/assets/images/botox1.jpg",
    },

    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
  morpheus8: {
    contentList: [
      {
        title: "Qu'est-ce que le Morpheus 8 ?",
        description:
          "Le Morpheus 8 est une technologie innovante combinant les micro-aiguilles et la radiofréquence pour remodeler et raffermir la peau en profondeur. Il s'agit d'un traitement non invasif, adapté pour le visage et le corps, qui stimule la production naturelle de collagène et d'élastine.",
      },
      {
        title: "Les avantages du traitement Morpheus 8",
        description:
          "Le traitement améliore la texture de la peau, réduit les cicatrices d'acné, les vergetures et les irrégularités cutanées, tout en raffermissant les tissus. Il peut être utilisé sur diverses zones du visage et du corps pour un rajeunissement global.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "MORPHEUS 8 VISAGE ET CORPS",
      subtitle: "",
      description:
        "Le Morpheus 8 est un traitement de pointe qui combine la radiofréquence et les micro-aiguilles pour améliorer la qualité de la peau, réduire les irrégularités et raffermir les tissus. Idéal pour le visage et le corps, il offre des résultats durables avec un minimum d'inconfort.",
      presentationImage: "/assets/images/morph1.png",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Le Morpheus 8 permet de remodeler et de raffermir la peau grâce à une action combinée des micro-aiguilles et de la radiofréquence. Ce traitement non invasif stimule la production de collagène pour un effet lissant et rajeunissant, adapté à toutes les zones du visage et du corps.",
      backgroundImage: "/assets/images/morph2.jpg",
    },
    composition: {
      title: "COMPOSITION",
      topImage:
        "https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-composition-top.jpg",
      bottomImage: "/assets/images/couv-actu-morpheus.webp",
      mainDescription: [
        {
          text: "Le Morpheus 8 combine des micro-aiguilles de haute précision et une radiofréquence ciblée pour atteindre les couches profondes de la peau, stimulant ainsi le collagène et améliorant la structure cutanée.",
          indent: 8,
        },
        {
          text: "Le dispositif est conçu pour être sûr et efficace, offrant des résultats visibles avec un minimum d'inconfort et de temps de récupération.",
          indent: 8,
        },
        {
          text: "Les traitements sont personnalisés pour répondre aux besoins spécifiques de chaque patient, garantissant des résultats optimaux.",
          indent: 3,
        },
      ],
      bottomText: {
        above:
          "Le Morpheus 8 est un dispositif médical certifié, utilisé par des professionnels formés pour garantir la sécurité et l'efficacité du traitement.",
        below:
          "Les protocoles de traitement sont adaptés en fonction des zones à traiter et des objectifs esthétiques du patient, offrant une expérience personnalisée.",
      },
    },
    sections: [
      "medecineEsthetique",
      "composition",
      "ceQuilFautSavoir",
      "questions",
    ],
  },
  filler: {
    contentList: [
      {
        title: "Les injections de comblement visage et corps",
        description:
          "Les produits de comblement sont des solutions innovantes qui restaurent naturellement le volume et redéfinissent les contours. Ces injections permettent de corriger les imperfections du visage (rides, volumes, contours) et du corps (fesses, hanches, mollets) pour des résultats harmonieux et naturels.",
      },
      {
        title: "Une solution complète de remodelage",
        description:
          "Les injections de comblement sont réalisées avec des techniques de pointe, utilisant des aiguilles ou des canules adaptées à chaque zone. Le traitement dure environ 30-45 minutes, avec des résultats visibles immédiatement. Les effets peuvent durer de 6 à 24 mois selon la zone traitée et le produit utilisé.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "INJECTIONS DE COMBLEMENT VISAGE ET CORPS",
      subtitle: "",
      description:
        "Les injections de comblement sont des traitements de médecine esthétique qui permettent de restaurer les volumes et redéfinir les contours du visage et du corps. Cette approche globale offre une solution complète pour un rajeunissement naturel du visage et un remodelage harmonieux du corps.",
      presentationImage:
        "https://www.drtarekaesthetics.com/wp-content/uploads/2023/10/Whats-the-Difference-Between-Body-Fillers-and-Botox-1024x538.jpg",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Les produits de comblement offrent une solution complète pour le visage et le corps. Au niveau du visage, ils permettent de corriger les rides, rehausser les pommettes, redessiner les lèvres et restructurer l'ovale du visage. Pour le corps, ils sont utilisés pour augmenter les volumes des fesses, redessiner les mollets, améliorer l'aspect des hanches et harmoniser la silhouette. Le praticien adapte les techniques d'injection et les produits selon chaque zone, garantissant des résultats naturels qui respectent l'harmonie des proportions.",
      backgroundImage: "/assets/images/filler2bg.png",
    },
    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
  sculptra: {
    contentList: [
      {
        title: "Comment fonctionne le Sculptra ?",
        description:
          "Le Sculptra est un produit unique qui stimule naturellement la production de collagène. Cette bio-stimulation progressive permet de restaurer les volumes perdus et d'améliorer la qualité de la peau du visage et du corps, avec des résultats qui se développent naturellement sur plusieurs mois.",
      },
      {
        title: "Une solution durable de rajeunissement",
        description:
          "Le traitement par Sculptra nécessite généralement 2 à 3 séances espacées d'un mois. Les résultats apparaissent progressivement sur 2-3 mois et peuvent durer jusqu'à 2 ans. Cette approche offre un rajeunissement naturel et durable du visage et du corps.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "TRAITEMENT SCULPTRA",
      subtitle: "",
      description:
        "Le Sculptra est un traitement innovant de bio-stimulation qui agit en profondeur pour restaurer progressivement les volumes et améliorer la qualité de la peau. Cette solution offre un rajeunissement global et durable, idéal pour le visage et certaines zones du corps nécessitant une restauration volumétrique.",
      presentationImage:
        "https://drromanovaleria.ch/wp-content/uploads/2024/05/sculptra-injection.webp",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Le Sculptra utilise l'acide poly-L-lactique pour stimuler naturellement la production de collagène. Pour le visage, il traite efficacement les zones creuses des tempes, des joues, et redéfinit l'ovale du visage. Sur le corps, il est particulièrement efficace pour améliorer l'aspect de la peau et restaurer les volumes des fesses, des bras et des zones présentant une perte de densité. Les résultats se développent progressivement, offrant un rajeunissement naturel et durable qui peut persister jusqu'à 2 ans après le traitement complet.",
      backgroundImage: "/assets/images/sculptrabg.jpg",
    },
    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
  threadLift: {
    contentList: [
      {
        title: "Comment fonctionnent les fils tenseurs ?",
        description:
          "Les fils tenseurs sont des fils médicaux résorbables implantés sous la peau qui permettent de créer un effet lifting immédiat. Cette technique permet de redéfinir les contours du visage, du cou et du corps en stimulant la production naturelle de collagène pour des résultats durables.",
      },
      {
        title: "Un lifting sans chirurgie",
        description:
          "La pose de fils tenseurs est une technique mini-invasive réalisée sous anesthésie locale. Le traitement dure environ 45-60 minutes selon les zones traitées. Les résultats sont visibles immédiatement et continuent de s'améliorer pendant 2-3 mois, avec des effets qui peuvent durer 12 à 18 mois.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "FILS TENSEURS",
      subtitle: "",
      description:
        "Les fils tenseurs représentent une alternative moderne au lifting chirurgical, offrant un rajeunissement naturel du visage et du corps. Cette technique permet de redéfinir les contours, améliorer la fermeté et stimuler la production de collagène pour des résultats progressifs et durables.",
      presentationImage: "/assets/images/fils.webp",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "Les fils tenseurs sont des fils résorbables spécialement conçus pour le lifting non chirurgical. Sur le visage, ils redessinent l'ovale, liftent les sourcils, corrigent l'affaissement des joues et du cou. Pour le corps, ils améliorent la fermeté des bras, du ventre, de l'intérieur des cuisses et des genoux. La technique combine un effet lift immédiat et une stimulation collagénique progressive, pour des résultats naturels qui évoluent dans le temps. Cette solution s'adapte parfaitement aux patients souhaitant un rajeunissement sans chirurgie.",
      backgroundImage: "/assets/images/threadLiftp.jpg",
    },
    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
  hairTransplant: {
    contentList: [
      {
        title: "La greffe de cheveux : une solution permanente",
        description:
          "La transplantation capillaire est une technique chirurgicale précise qui consiste à prélever des follicules pileux dans une zone donneuse (généralement l'arrière de la tête) pour les réimplanter dans les zones dégarnis. Cette solution offre des résultats naturels et définitifs.",
      },
      {
        title: "Technique FUE - Extraction Folliculaire Unitaire",
        description:
          "La méthode FUE permet une extraction précise des unités folliculaires une par une, sans cicatrice linéaire. L'intervention dure entre 6 à 8 heures sous anesthésie locale. Les premiers résultats sont visibles après 3-4 mois, avec un résultat final à 12-18 mois.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "GREFFE DE CHEVEUX",
      subtitle: "",
      description:
        "La greffe de cheveux est une intervention de chirurgie esthétique qui permet de restaurer durablement la densité capillaire. Cette technique moderne offre une solution définitive à la calvitie et aux zones dégarnis, avec des résultats naturels qui respectent la ligne des cheveux et l'harmonie du visage.",
      presentationImage: "/assets/images/woman-curly-hairstyle-commercial.jpg",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "La transplantation capillaire moderne utilise la technique FUE (Follicular Unit Extraction), qui permet une extraction et une réimplantation précise des unités folliculaires. Le processus commence par un design personnalisé de la ligne des cheveux, suivi de l'extraction minutieuse des greffons de la zone donneuse. Ces follicules sont ensuite implantés un par un dans les zones à traiter, en respectant l'angle et la direction naturelle des cheveux. Cette approche méticuleuse garantit des résultats naturels et harmonieux, avec une repousse progressive qui devient visible après quelques mois.",
      backgroundImage:
        "/assets/images/beautiful-model-with-long-smooth-flying-brunette-hair-isolated-white-studio-background.jpg",
    },
    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
  hydrafacial: {
    contentList: [
      {
        title: "Le soin Hydrafacial : une révolution pour la peau",
        description:
          "L'Hydrafacial est un soin complet qui combine nettoyage profond, exfoliation, extraction et hydratation intense de la peau. Cette technologie avancée permet un renouvellement cutané en profondeur tout en apportant des actifs nourrissants pour une peau éclatante.",
      },
      {
        title: "Un protocole en plusieurs étapes",
        description:
          "Le traitement Hydrafacial se déroule en 6 étapes distinctes : nettoyage, peeling, extraction, hydratation, protection et personnalisation avec des boosters spécifiques. La séance dure environ 30-45 minutes, sans temps de récupération, pour un résultat visible immédiatement.",
      },
    ],
    light: false,
    iconColor: "black",
    inputControl: "white",
    medecineEsthetique: {
      title: "HYDRAFACIAL",
      subtitle: "",
      description:
        "L'Hydrafacial est un soin visage haute technologie qui combine plusieurs actions pour une peau parfaitement nettoyée, régénérée et éclatante. Cette technique révolutionnaire offre une solution complète pour tous les types de peau, avec des résultats visibles dès la première séance.",
      presentationImage:
        "/assets/images/woman-making-beauty-procedures-beauty-salon.jpg",
      presentationTitle: "PRÉSENTATION GÉNÉRALE",
      presentationText:
        "L'Hydrafacial utilise une technologie brevetée de Vortex-Fusion pour un soin complet du visage. Le traitement débute par un nettoyage en profondeur et une exfoliation douce qui élimine les cellules mortes. Suit une étape de peeling doux pour affiner le grain de peau, puis une extraction douce mais efficace des impuretés. La peau est ensuite nourrie avec un cocktail d'antioxydants, peptides et acide hyaluronique. La séance se termine par une protection personnalisée selon les besoins spécifiques de votre peau. Ce protocole unique permet d'obtenir une peau purifiée, hydratée et éclatante de santé.",
      backgroundImage: "/assets/images/beautiful-girl-with-wet-skin.jpg",
    },
    sections: ["medecineEsthetique", "ceQuilFautSavoir", "questions"],
  },
};

export type PageDataType = typeof pageData;
export type PageSlug = keyof PageDataType;
