import React, { useState } from "react";
import { User, Award, BookOpen, Users, Heart } from "lucide-react";

const Biographie = () => {
  const [activeSection, setActiveSection] = useState("about");

  const handleSectionChange = (id: React.SetStateAction<string>) => {
    setActiveSection(id);
  };

  const navigation = [
    { id: "about", label: "À Propos", icon: User },
    { id: "education", label: "Formation", icon: BookOpen },
    { id: "approach", label: "Approche", icon: Heart },
    { id: "team", label: "Équipe", icon: Users },
  ];

  return (
    <div className="min-h-screen pb-24">
      {/* Hero Section */}
      <div className="relative h-[80dvh]">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="/assets/videos/dr-youssef-intro.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-darkB opacity-30"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white px-4">
          <img
            src="/assets/images/dr-youssef-gam.jpeg"
            alt="Dr. Youssef Gam"
            className="rounded-full w-32 h-32 mb-4 border-4 object-cover bg-no-repeat border-white shadow-lg transform hover:scale-105 transition-transform duration-300"
          />
          <h1 className="text-4xl font-bold mb-2 text-lightB">
            Dr. Youssef Gam
          </h1>
          <p className="text-xl text-lightB">Chirurgien Plasticien</p>
        </div>
      </div>

      {/* Navigation */}
      <nav className="bg-white shadow sticky top-0 z-50 transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-center space-x-8">
            {navigation.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => handleSectionChange(id)}
                className={`flex items-center px-3 py-4 text-sm font-medium transition-all duration-300 ease-in-out ${
                  activeSection === id
                    ? "text-[rgba(183,171,152,1)] border-b-2 border-[rgba(183,171,152,1)] !font-bold scale-105"
                    : "text-gray600 hover:text-gray800 hover:bg-gray100"
                }`}
              >
                <Icon
                  className={`w-5 h-5 mr-2 transition-transform duration-300 ${
                    activeSection === id ? "scale-110 fill-current" : ""
                  }`}
                />
                {label}
              </button>
            ))}
          </div>
        </div>
      </nav>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* About Section */}
        <div
          className={`transform transition-all duration-300 ${
            activeSection === "about"
              ? "translate-x-0 opacity-100"
              : "translate-x-full opacity-0 hidden"
          }`}
        >
          <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold mb-6 text-blue-800">
              Parcours Professionnel
            </h2>
            <p className="text-gray800 mb-6 leading-relaxed font-bold">
              Le Dr Youssef Gam est un chirurgien plasticien distingué, avec une
              carrière remarquable en Tunisie et en France. En tant qu'ancien
              praticien attaché des Hôpitaux de Paris, il a exercé dans des
              cliniques privées prestigieuses, se forgeant une réputation
              d'excellence et d'innovation dans le domaine de la chirurgie
              esthétique.
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-gradient-to-br from-blue-50 to-gray100 py-6 rounded-lg transform hover:scale-105 transition-transform duration-300">
                <h3 className="text-xl font-semibold mb-4 text-blue-700">
                  Expertise
                </h3>
                <p className="font-bold">
                  Grâce à ses multiples collaborations et formations à travers
                  le monde, le Dr Gam propose des solutions adaptées dans tous
                  les domaines de la chirurgie esthétique et plastique.
                </p>
              </div>
              <div className="bg-gradient-to-br from-blue-50 to-gray100 py-6 rounded-lg transform hover:scale-105 transition-transform duration-300">
                <h3 className="text-xl font-semibold mb-4 text-blue-700">
                  Innovation
                </h3>
                <p className="font-bold">
                  Son engagement constant envers l'innovation et l'excellence
                  lui permet de répondre aux standards les plus élevés de la
                  chirurgie esthétique moderne.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Education Section */}
        <div
          className={`transform transition-all duration-300 ${
            activeSection === "education"
              ? "translate-x-0 opacity-100"
              : "-translate-x-full opacity-0 hidden"
          }`}
        >
          <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold mb-6 text-blue-800">
              Formation Académique
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              {[
                {
                  title: "Chirurgie du Visage",
                  institution: "Université Nice Côte d'Azur",
                },
                {
                  title: "Chirurgie Esthétique Génitale",
                  institution: "Université de Créteil",
                },
                {
                  title: "Techniques d'Injections",
                  institution: "Université de Créteil",
                },
                {
                  title: "Occuloplastie Esthétique",
                  institution: "Université de Paris",
                },
                {
                  title: "Management des Structures Sanitaires",
                  institution: "Université de Nancy",
                },
              ].map((diploma, index) => (
                <div
                  key={index}
                  className="flex items-start p-4 bg-gradient-to-br from-blue-50 to-gray100 rounded-lg transform hover:scale-105 transition-transform duration-300"
                >
                  <Award className="w-6 h-6 text-[rgba(183,171,152,1)] mt-1 mr-4" />
                  <div>
                    <h3 className="font-semibold text-lg text-blue-700">
                      {diploma.title}
                    </h3>
                    <p className="text-gray-600">{diploma.institution}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Approach Section */}
        <div
          className={`transform transition-all duration-300 ${
            activeSection === "approach"
              ? "translate-x-0 opacity-100"
              : "translate-y-full opacity-0 hidden"
          }`}
        >
          <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold mb-6 text-blue-800">
              Approche Holistique
            </h2>
            <p className="text-gray800 mb-8 leading-relaxed font-bold">
              Dans une démarche centrée sur le bien-être et les résultats
              durables, le Dr Gam privilégie une approche holistique de la
              chirurgie esthétique. Cette vision garantit des résultats qui ne
              se limitent pas à l'esthétique mais qui englobent également la
              santé et le bien-être physique et mental.
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="rounded-lg shadow-md overflow-hidden w-full h-[36vh]">
                <img
                  src="/assets/images/cabinet.jpg"
                  alt="Consultation"
                  className="w-full h-full object-center object-cover transition-transform duration-700 hover:scale-110"
                />
              </div>
              <div className="space-y-4 bg-gradient-to-br from-blue-50 to-gray100 py-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-blue-700">
                  Notre Engagement
                </h3>
                <p className="font-bold">
                  Chaque intervention est précédée d'une consultation
                  approfondie pour comprendre vos attentes et vous proposer les
                  solutions les plus adaptées à votre situation.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Team Section */}
        <div
          className={`transform transition-all duration-300 ${
            activeSection === "team"
              ? "translate-x-0 opacity-100"
              : "-translate-y-full opacity-0 hidden"
          }`}
        >
          <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold mb-6 text-blue-800">
              Équipe Multidisciplinaire
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              {[
                {
                  role: "Médecin Nutritionniste",
                  description:
                    "Équilibre et optimisation de l'état de santé global",
                },
                {
                  role: "Psychologue",
                  description: "Accompagnement émotionnel personnalisé",
                },
                {
                  role: "Sophrologue",
                  description: "Préparation mentale et gestion du stress",
                },
                {
                  role: "Kinésithérapeute",
                  description: "Récupération physique optimale",
                },
              ].map((member, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-gray100 py-6 rounded-lg transform hover:scale-105 transition-transform duration-300"
                >
                  <h3 className="text-xl font-semibold mb-3 text-blue-700">
                    {member.role}
                  </h3>
                  <p className="text-gray-600">{member.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biographie;
