interface Content {
    title: string;
    description: string;
  }
  
  interface ParagraphSection {
    mainDescription: string;
    content: Content[];
  }
  
  interface ParagraphContent {
    [key: string]: ParagraphSection;
  }
  
  export const paragraphContent: ParagraphContent = {
    "Augmentation mammaire par prothèse":{
        mainDescription: "L'hypoplasie mammaire se caractérise par un volume insuffisant des seins par rapport à la morphologie de la patiente, résultant soit d'un développement glandulaire insuffisant à la puberté, soit de pertes ultérieures de volume suite à la grossesse, à l'allaitement, à l'amaigrissement ou à des déséquilibres hormonaux.",
        content: [
          {
            title: "Mode opératoire",
            description: "La mammoplastie consiste à augmenter la taille des seins en insérant des prothèses pré-remplies de gel de silicone, choisies lors de la consultation avec le Dr Trevidic et après des essais, à travers différentes incisions selon les caractéristiques anatomiques de la patiente, soit au niveau du segment inférieur de la circonférence de l'aréole, soit au niveau du sillon sous-mammaire, avec deux positionnements possibles des prothèses par rapport aux muscles pectoraux, pré-musculaire ou rétro-musculaire, déterminés en consultation, et réalisée sous anesthésie générale en une heure."
          },
          {
            title: "Suites opératoires", 
            description: "Après la chirurgie, la patiente portera un pansement modelant pendant 5 jours, suivi d'un soutien-gorge médical pendant 2 semaines, avec possibilité de douleur et de gêne initiales nécessitant un traitement antalgique, tandis que le retour aux activités normales, y compris le soleil, les bains de mer et le sport, est possible après 2 semaines, avec un délai de 2 à 3 mois pour apprécier pleinement les résultats. En cas de ptose mammaire, une réduction de l'enveloppe cutanée peut être associée, et lors de la consultation, le Dr. Trevidic présentera plusieurs cas cliniques Avant-Après."
          },
          {
            title: "Question diverses",
            description: "Il est recommandé d'attendre au moins 6 mois après la pose des prothèses mammaires avant d'envisager une grossesse, bien que l'allaitement soit généralement possible selon la patiente, et une augmentation mammaire est envisageable 6 mois après une grossesse ou un allaitement. L'implantation de prothèses mammaires n'augmente pas le risque de cancer du sein et n'interfère pas avec les examens radiologiques, mais il est crucial de signaler systématiquement leur présence. Le Dr. Trevidic utilise exclusivement des implants lisses."
          }
        ]
      },
    "Lipolifting du sein":
    {
        mainDescription: "Le lipolifting du sein est une technique naturelle d'augmentation mammaire qui utilise la graisse de la patiente. Cette méthode permet d'obtenir un résultat harmonieux et naturel tout en remodelant d'autres zones du corps par lipoaspiration.",
        content: [
          {
            title: "Mode opératoire",
            description: "L'intervention se déroule en deux étapes : d'abord une lipoaspiration douce des zones présentant un excès de graisse (hanches, abdomen, cuisses) pour prélever la graisse. Cette graisse est ensuite purifiée et réinjectée de manière précise dans les seins pour augmenter leur volume et améliorer leur forme. L'intervention est réalisée sous anesthésie locale ou générale selon les cas, et dure environ 2 heures. Les incisions sont minimes et quasi invisibles."
          },
          {
            title: "Suites opératoires",
            description: "Les suites sont généralement simples avec un œdème et des ecchymoses qui disparaissent en 10 à 15 jours. Un soutien-gorge de maintien doit être porté pendant 1 mois. La reprise des activités quotidiennes est possible après quelques jours, mais les sports et activités intenses doivent être évités pendant 3 à 4 semaines. Le résultat définitif s'apprécie après 3 mois, une fois que la graisse greffée s'est stabilisée."
          },
          {
            title: "Question diverses",
            description: "Le lipolifting mammaire permet une augmentation de volume modérée, généralement d'une taille de bonnet. Cette technique est particulièrement adaptée aux patientes souhaitant un résultat naturel et disposant de zones donneuses suffisantes. Les résultats sont durables dans le temps, la graisse greffée se comportant comme la graisse naturelle du sein. Cette technique peut être combinée avec d'autres procédures mammaires selon les besoins spécifiques de chaque patiente."
          }
        ]
      },
    "Lifting des seins avec prothèse": {
        mainDescription: "Le lifting des seins avec prothèse est une technique chirurgicale combinée qui permet de corriger à la fois l'affaissement mammaire (ptose) et le manque de volume. Cette intervention est particulièrement adaptée aux patientes présentant une ptose mammaire associée à un manque de volume, notamment après une grossesse, un allaitement ou une perte de poids importante.",
        content: [
          {
            title: "Mode opératoire",
            description: "L'intervention combine deux techniques chirurgicales : le lifting mammaire qui permet de remonter l'aréole et de retendre la peau du sein, et la pose de prothèses mammaires pour restaurer le volume. L'opération débute par le repositionnement de l'aréole et du mamelon à la bonne hauteur, suivi de la création d'une loge pour accueillir la prothèse. Les incisions sont généralement en forme de T inversé ou d'ancre autour de l'aréole et dans le sillon sous-mammaire. L'intervention est réalisée sous anesthésie générale et dure environ 2 à 3 heures."
          },
          {
            title: "Suites opératoires",
            description: "Les suites opératoires nécessitent le port d'un soutien-gorge médical spécial pendant 6 semaines. Les premiers jours peuvent être marqués par un œdème et des ecchymoses qui se résorbent progressivement. Les fils de suture sont résorbables ou retirés après 15 jours. Un arrêt de travail de 2 à 3 semaines est généralement nécessaire. Les activités sportives peuvent être reprises progressivement après 6 semaines. Le résultat définitif s'apprécie après 3 à 6 mois, une fois que les seins auront retrouvé leur souplesse naturelle."
          },
          {
            title: "Question diverses",
            description: "Cette technique permet d'obtenir des seins à la fois plus hauts et plus volumineux, avec des cicatrices qui s'estompent progressivement. Les prothèses utilisées sont choisies en fonction de la morphologie de la patiente et de ses souhaits, tout en respectant ses proportions naturelles. Un suivi régulier est nécessaire pour surveiller les prothèses, et des examens radiologiques spécifiques peuvent être réalisés. La durée de vie des prothèses est d'environ 10 à 15 ans, et un changement peut être envisagé après cette période selon les recommandations médicales."
          }
        ]
      },
      "Lifting des seins sans prothèse" :
      {
        mainDescription: "Le lifting des seins sans prothèse, ou mastopexie pure, est une intervention chirurgicale qui permet de corriger l'affaissement mammaire (ptose) en redonnant aux seins leur position naturelle, sans ajout de volume. Cette technique est idéale pour les patientes satisfaites de leur volume mammaire mais qui souhaitent corriger un relâchement cutané, souvent consécutif à une grossesse, un allaitement, une perte de poids importante ou au vieillissement naturel.",
        content: [
          {
            title: "Mode opératoire",
            description: "L'intervention consiste à remodeler le sein en supprimant l'excès de peau et en repositionnant l'aréole et le mamelon à une hauteur plus naturelle. Les incisions peuvent être réalisées soit uniquement autour de l'aréole (lifting périaréolaire), soit en forme de T inversé ou d'ancre selon le degré de ptose. La glande mammaire est également restructurée pour assurer un galbe harmonieux. L'opération est réalisée sous anesthésie générale et dure environ 2 heures. Cette technique permet de préserver la sensibilité du mamelon et la possibilité d'allaiter dans la majorité des cas."
          },
          {
            title: "Suites opératoires",
            description: "Les suites nécessitent le port d'un soutien-gorge médical de contention pendant 4 à 6 semaines. Les premiers jours peuvent être marqués par un œdème et des ecchymoses qui se résorbent progressivement. Les fils de suture sont retirés ou résorbés après 15 jours. Un arrêt de travail de 1 à 2 semaines est généralement conseillé. La reprise des activités physiques douces est possible après 3 semaines, et les sports plus intenses après 6 semaines. Le résultat définitif s'apprécie après 3 à 6 mois, lorsque les seins auront retrouvé leur souplesse naturelle et que les cicatrices se seront estompées."
          },
          {
            title: "Question diverses",
            description: "Cette intervention donne des résultats durables, mais n'empêche pas l'évolution naturelle des seins avec le temps, la grossesse ou les variations pondérales. Les cicatrices, bien que permanentes, s'atténuent progressivement et sont placées de manière à être dissimulées sous les sous-vêtements. La mastopexie peut être réalisée à tout âge après 18 ans, mais il est préférable d'attendre la fin des grossesses désirées. Les examens de dépistage du cancer du sein (mammographies, échographies) restent possibles et ne sont pas affectés par l'intervention."
          }
        ]
      },
      "Breast Tite lifting des seins sans cicatrices" :
      {
        mainDescription: "Le Breast Tite est une technique innovante de lifting mammaire qui utilise la radiofréquence assistée pour raffermir et remodeler les seins sans cicatrices visibles. Cette technologie minimalement invasive permet de traiter le relâchement cutané léger à modéré en combinant le resserrement de la peau et la rétraction des tissus, offrant une alternative aux techniques chirurgicales traditionnelles.",
        content: [
          {
            title: "Mode opératoire",
            description: "L'intervention se réalise sous anesthésie locale avec sédation légère. Une fine canule émettant de la radiofréquence est introduite à travers de micro-incisions dissimulées dans le sillon sous-mammaire. La radiofréquence chauffe de manière contrôlée et uniforme les tissus à différentes profondeurs, provoquant une rétraction immédiate du collagène et stimulant sa production naturelle. Un capteur thermique intégré permet de contrôler en temps réel la température des tissus pour garantir sécurité et efficacité. La procédure dure environ 1 à 2 heures selon la zone à traiter."
          },
          {
            title: "Suites opératoires",
            description: "Les suites sont généralement simples avec peu de douleurs. Un pansement compressif ou un soutien-gorge médical doit être porté pendant 3 à 4 semaines. Les patientes peuvent reprendre leurs activités quotidiennes après 2 à 3 jours. Les ecchymoses et l'œdème sont minimes et se résorbent en quelques jours. Une légère sensation de tension peut persister quelques semaines. Le résultat s'améliore progressivement sur 3 à 6 mois, au fur et à mesure que le nouveau collagène se forme et que la peau se raffermit."
          },
          {
            title: "Question diverses",
            description: "Cette technique est particulièrement adaptée aux patientes présentant une ptose légère à modérée et souhaitant éviter les cicatrices d'un lifting traditionnel. Les résultats sont naturels et durables, mais peuvent nécessiter une ou deux séances d'entretien selon l'évolution. Le Breast Tite ne permet pas de corriger les ptoses importantes ou d'augmenter le volume des seins. Les meilleurs résultats sont obtenus chez les patientes ayant une bonne qualité de peau et une ptose modérée. Cette technique peut être combinée avec une lipoaspiration ou un lipofilling pour optimiser les résultats."
          }
        ]
      },
      "Réduction mammaire" :
      {
        mainDescription: "La réduction mammaire est une intervention chirurgicale qui permet de diminuer le volume des seins lorsque ceux-ci sont trop volumineux (hypertrophie mammaire). Cette chirurgie vise à soulager les douleurs physiques, améliorer le confort quotidien et harmoniser la silhouette. Elle s'adresse aux femmes souffrant de problèmes fonctionnels liés au poids de leur poitrine : douleurs cervicales, dorsales, gêne dans les activités physiques et difficultés vestimentaires.",
        content: [
          {
            title: "Mode opératoire",
            description: "L'intervention est réalisée sous anesthésie générale et dure environ 2 à 3 heures. Le chirurgien retire l'excès de glande mammaire, de graisse et de peau tout en préservant la vascularisation et la sensibilité de l'aréole et du mamelon. Les incisions sont généralement en forme d'ancre ou de T inversé, permettant de remodeler le sein et de repositionner l'aréole à une hauteur naturelle. La technique chirurgicale est adaptée à chaque patiente en fonction de sa morphologie et du volume à retirer. Une symétrie optimale est recherchée, bien qu'une légère asymétrie puisse persister naturellement."
          },
          {
            title: "Suites opératoires",
            description: "Les suites nécessitent le port d'un soutien-gorge médical spécial pendant 6 semaines, jour et nuit. Un drainage est maintenu pendant 24 à 48 heures. Les fils sont retirés entre 15 et 21 jours après l'intervention. Un arrêt de travail de 2 à 3 semaines est généralement nécessaire. Les ecchymoses et l'œdème se résorbent progressivement en quelques semaines. Les activités sportives peuvent être reprises progressivement après 6 semaines. La cicatrisation complète et le résultat définitif s'apprécient après 6 mois à 1 an, lorsque les seins auront retrouvé leur souplesse naturelle et que les cicatrices se seront estompées."
          },
          {
            title: "Question diverses",
            description: "L'intervention est souvent prise en charge par l'assurance maladie lorsque le volume retiré est important et que des symptômes fonctionnels sont présents. L'allaitement reste possible dans la majorité des cas, mais sa qualité peut être altérée. Les examens de dépistage du cancer du sein restent réalisables normalement. Les résultats sont définitifs, mais les seins continuent d'évoluer naturellement avec l'âge, le poids et les grossesses. Une stabilité pondérale est recommandée pour maintenir les résultats. Il est préférable d'envisager cette intervention après les grossesses désirées, bien qu'elle reste possible avant."
          }
        ]
      }
  };