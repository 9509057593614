import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import MenuIcon from "./icons/MenuIcon";
import CloseIcon from "./icons/CloseIcon";

const ResponsiveAppBar = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [atTop, setAtTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  const controlNavbar = () => {
    if (window.scrollY === 0) {
      setShow(true);
      setAtTop(true);
    } else if (window.scrollY > lastScrollY && window.scrollY > 50) {
      setShow(false);
      setAtTop(false);
    } else if (window.scrollY < lastScrollY || window.scrollY === lastScrollY) {
      setShow(true);
      setAtTop(false);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav
        className={`fixed w-full z-20 items-center p-6 transition-all duration-500 ease-in-out ${
          show ? "translate-y-0" : "-translate-y-full"
        } ${atTop ? "bg-transparent" : "bg-lightB"}`}
        style={{
          backgroundColor: atTop
            ? "rgba(255, 255, 255, 0)"
            : "rgba(255, 255, 255, 1)",
        }}
      >
        <div className="hidden md:flex justify-between">
          <Link to={"/"}>
            <div
              className={`flex items-center pl-24 hover:bg-opacity-50 ${
                atTop ? "text-lightB" : "text-darkB"
              }`}
            >
              <img
                src={` ${
                  atTop
                    ? "/assets/images/symbole blanc.png"
                    : "/assets/images/symbole noir.png"
                }`}
                alt="logo Dr. Youssef Gam"
                className="h-8 object-contain pr-5"
              />
              <div className="text-xl font-primaryMedium ">Dr. Youssef Gam</div>
            </div>
          </Link>
          <ul
            className={`flex  items-center space-x-6 pr-28 ${
              atTop ? "text-lightB" : "text-darkB"
            }`}
          >
            <li className="relative cursor-pointer text-l font-primaryMedium pr-7 group">
              Médecine esthétique
              <ul className="absolute px-[0.6rem] text-center leading-[2rem] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out bg-lightB text-darkB p-4 transform translate-y-2 group-hover:translate-y-0">
                <Link to={"/acide-hyaluronique"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Acide Hyaluronique
                    </button>
                  </li>
                </Link>
                <Link to={"/botox"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Botox
                    </button>
                  </li>
                </Link>
                <Link to={"/morpheus8"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Morpheus 8
                    </button>
                  </li>
                </Link>
                <Link to={"/filler"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Visage Et Corps
                    </button>
                  </li>
                </Link>
                <Link to={"/sculptra"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Sculptra
                    </button>
                  </li>
                </Link>
                <Link to={"/threadLift"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Fils Tenseurs
                    </button>
                  </li>
                </Link>
                <Link to={"/hairTransplant"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Greffe De Cheveux
                    </button>
                  </li>
                </Link>
                <Link to={"/hydrafacial"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Hydrafacial
                    </button>
                  </li>
                </Link>
              </ul>
            </li>
            <li className="relative cursor-pointer text-l font-primaryMedium pr-7 group">
              Chirurgie esthétique
              <ul className="absolute px-[3.6rem] text-center leading-[2rem] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out bg-lightB text-darkB p-4 transform translate-y-2 group-hover:translate-y-0">
                <Link to={"/corps"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Corps
                    </button>
                  </li>
                </Link>
                <Link to={"/seins"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Seins
                    </button>
                  </li>
                </Link>
                <Link to={"/visage"}>
                  <li>
                    <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-[-1px] before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-darkB before:via-darkB before:to-darkB hover:before:w-full hover:before:opacity-100">
                      Visage
                    </button>
                  </li>
                </Link>
              </ul>
            </li>
            <li
              className={`cursor-pointer text-l font-primaryMedium ${
                isContactPage
                  ? "text-black"
                  : atTop
                  ? "text-lightB"
                  : "text-darkB"
              }`}
            >
              <Link to={"/contact"}>
                <button className="capitalize font-primaryMedium inline-block relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-0.5 before:rounded before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-lightB before:via-lightB before:to-lightB hover:before:w-full hover:before:opacity-100">
                  Contact
                </button>
              </Link>
            </li>
          </ul>
        </div>

        <div
          className={`md:hidden ${
            isContactPage ? "text-black" : atTop ? "text-lightB" : "text-darkB"
          } pr-6`}
        >
          <button onClick={toggleNavbar} className="focus:outline-none">
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>

        <div
          className={`md:hidden fixed inset-0 bg-lightB h-screen  transition-all duration-300 ease-in-out ${
            isOpen
              ? "opacity-100 visible translate-y-16"
              : "opacity-0 invisible translate-y-0"
          } z-50`}
        >
          <div className="p-8 bg-lightB">
            <ul className="space-y-6">
              <li>
                <Link
                  className="text-xl font-primaryMedium"
                  to="/"
                  onClick={() => setIsOpen(false)}
                >
                  Accueil
                </Link>
              </li>
              <li className="cursor-pointer text-xl font-primaryMedium">
                Médecine esthétique
                <ul className="pl-4 space-y-4 mt-4">
                  <Link
                    to="/acide-hyaluronique"
                    onClick={() => setIsOpen(false)}
                  >
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Acide Hyaluronique
                    </li>
                  </Link>
                  <Link to="/botox" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Botox
                    </li>
                  </Link>
                  <Link to="/morpheus8" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Morpheus 8
                    </li>
                  </Link>
                  <Link to="/filler" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Visage Et Corps
                    </li>
                  </Link>
                  <Link to="/sculptra" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Sculptra
                    </li>
                  </Link>
                  <Link to="/threadLift" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Fils Tenseurs
                    </li>
                  </Link>
                  <Link to="/hairTransplant" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Greffe De Cheveux
                    </li>
                  </Link>
                  <Link to="/hydrafacial" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Hydrafacial
                    </li>
                  </Link>
                </ul>
              </li>
              <li className="cursor-pointer text-xl font-primaryMedium">
                Chirurgie esthétique
                <ul className="pl-4 space-y-4 mt-4">
                  <Link to="/corps" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Corps
                    </li>
                  </Link>
                  <Link to="/seins" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Seins
                    </li>
                  </Link>
                  <Link to="/visage" onClick={() => setIsOpen(false)}>
                    <li className="text-darkB text-lg hover:text-gray-600 transition-colors duration-200">
                      Visage
                    </li>
                  </Link>
                </ul>
              </li>
              <li>
                <Link
                  className="text-xl font-primaryMedium"
                  to="/contact"
                  onClick={() => setIsOpen(false)}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ResponsiveAppBar;
