import { Link } from "react-router-dom";
import ImageBannerAnimation from "./ImageBannerAnimation";
import body from "../assets/img/hero-bg.png";

function Questions() {
  return (
    <div className="relative flex flex-col sm:flex-row mt-[-6rem] mb-[15vh] h-auto sm:h-[50vh]">
      <div className="bg-lightBlue w-full flex flex-col justify-between">
        <h1 className="text-darkB text-center text-4xl sm:text-5xl mx-5 mt-10">
          DES QUESTIONS ?
        </h1>
        <div className=" text-center mb-10 mt-5 sm:mt-0">
          <Link to="/contact">
            <button className="black_button text-xl">Contactez-Nous</button>
          </Link>
        </div>
      </div>
      <div className="overflow-hidden w-full ">
        <ImageBannerAnimation imageUrl={body} />
      </div>
    </div>
  );
}

export default Questions;
