import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme, useMediaQuery } from "@mui/material";

export interface ContentItem {
  readonly title: string;
  readonly description: string;
}

interface InputControlProps {
  readonly light: boolean;
  readonly contentList: ReadonlyArray<ContentItem>;
  readonly inputControl: "transparent" | "white";
  readonly iconColor: "white" | "black";
}

const Accordion = styled((props: AccordionProps & { inputControl: string }) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, inputControl }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  backgroundColor: inputControl === "transparent" ? "transparent" : "white",
  margin: "0 1rem",
  [theme.breakpoints.up("sm")]: {
    margin: 0,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.125rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontSize: "0.875rem",
  lineHeight: "1.5",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.125rem",
    padding: theme.spacing(4),
  },
}));

function CeQuilFautSavoirSection({
  light,
  contentList,
  inputControl,
  iconColor,
}: InputControlProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="w-full max-w-[110vh] sm:max-w-[90vw] sm:mx-auto">
      {contentList.map((content, index) => (
        <Accordion
          key={`panel${index + 1}`}
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
          inputControl={inputControl}
          sx={{
            borderTop: expanded === `panel${index}` ? 0 : 1,
            borderBottom: index + 1 === contentList.length ? 1 : 0,
            boxShadow: 0,
            borderRadius: 0,
            mt: isMobile ? "0.5rem" : "1rem",
            color: light ? "white" : "black",
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel${index + 1}` ? (
                <RemoveIcon
                  sx={{
                    color: iconColor,
                    fontSize: isMobile ? "1.25rem" : "1.5rem",
                  }}
                />
              ) : (
                <AcUnitIcon
                  sx={{
                    color: iconColor,
                    fontSize: isMobile ? "1.25rem" : "1.5rem",
                  }}
                />
              )
            }
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
            sx={{
              mt: isMobile ? "0.5rem" : "1rem",
              color: light ? "white" : "black",
              mb: "1rem",
              minHeight: isMobile ? "64px" : "64px",
              "& .MuiAccordionSummary-content": {
                margin: isMobile ? "8px 0" : "12px 0",
              },
            }}
          >
            {content.title}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              border: 1,
              padding: isMobile ? "8px" : "1rem",
            }}
          >
            {content.description}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default CeQuilFautSavoirSection;
