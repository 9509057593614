import { useRef, useLayoutEffect, useState } from "react";

interface ImageBannerAnimationProps {
  imageUrl: string;
}

const ImageBannerAnimation2 = ({ imageUrl }: ImageBannerAnimationProps) => {
  const preScroll = useRef<number | null>(null);
  const elemRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  useLayoutEffect(() => {
    const botPos = (element: HTMLElement) =>
      element.getBoundingClientRect().bottom;
    const onScroll = () => {
      const divBotPos = botPos(elemRef.current!);
      const scrollPos = preScroll.current! > window.scrollY;
      preScroll.current = window.scrollY;
      if (scrollPos === null) {
        return;
      }
      if (scrollPos && divBotPos > window.innerHeight) {
        setScale(1);
        return;
      }
      if (divBotPos < window.innerHeight) {
        setScale(1.05);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div ref={elemRef}>
      <img
        src={imageUrl}
        alt="Hero main banner"
        className=" object-cover md:h-[500px] transition-transform duration-1000 ease-out"
        style={{ transform: `scale(${scale})` }}
      />
    </div>
  );
};

export default ImageBannerAnimation2;
