interface ContentItem {
    title: string;
    description: string;
  }
  
  interface ParagraphContent {
    mainDescription: string;
    content: ContentItem[];
  }
  
  interface VisageContent {
    [key: string]: ParagraphContent;
  }
  
  export const paragraphContent: VisageContent = {
    "Rhinoplastie Conventionnelle": {
        "mainDescription": "La rhinoplastie conventionnelle est une intervention chirurgicale qui permet de modifier la forme et les proportions du nez pour l'harmoniser avec le reste du visage. Cette technique s'adresse aux patients souhaitant corriger des imperfections esthétiques (bosse, pointe tombante, nez trop large) et/ou fonctionnelles (déviation de cloison, difficultés respiratoires) de leur nez.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie générale et dure entre 1h30 et 2h30. Elle peut être pratiquée par voie fermée (cicatrices uniquement à l'intérieur des narines) ou par voie ouverte (petite cicatrice supplémentaire sous la columelle). Le chirurgien remodèle les structures osseuses et cartilagineuses du nez selon le projet établi lors des consultations. Les modifications peuvent concerner la bosse, la pointe, les narines, la cloison nasale ou l'ensemble de ces éléments. Des greffons cartilagineux peuvent être utilisés pour renforcer ou affiner certaines zones. En fin d'intervention, un pansement contensif et une attelle sont mis en place pour protéger et maintenir les nouvelles structures."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites immédiates sont marquées par un œdème et des ecchymoses autour des yeux qui disparaissent en 10-15 jours. L'attelle nasale est retirée après 7 à 10 jours. Des mèches ou des tampons peuvent être placés dans les narines pendant 24-48h. La respiration nasale peut être gênée pendant quelques jours à quelques semaines. La reprise du travail est possible après 10-15 jours. Les activités physiques et sportives doivent être évitées pendant 1 à 3 mois selon leur intensité. Le résultat définitif s'apprécie après 6 mois à 1 an, temps nécessaire à la résorption complète de l'œdème et à la stabilisation des tissus."
          },
          {
            "title": "Question diverses",
            "description": "La rhinoplastie peut être prise en charge partiellement par l'assurance maladie en cas de problème fonctionnel (déviation de cloison, troubles respiratoires). Les résultats sont définitifs mais continuent d'évoluer naturellement avec l'âge. Une seconde intervention (reprise) peut parfois être nécessaire pour des ajustements mineurs dans 5 à 10% des cas. Il est important d'avoir une croissance nasale achevée avant l'intervention (généralement après 16-17 ans) et d'être en bonne santé. Le tabac est fortement déconseillé car il peut compromettre la cicatrisation. Des photographies et des simulations numériques sont réalisées lors des consultations pour définir le projet et visualiser les modifications envisagées."
          }
        ]
      },
    "Rhinoplastie Ethnique":{
        "mainDescription": "La rhinoplastie ethnique est une intervention chirurgicale spécialisée qui vise à modifier l'apparence du nez tout en préservant les caractéristiques ethniques et l'harmonie naturelle du visage. Cette technique respecte les particularités morphologiques propres à chaque origine (africaine, asiatique, méditerranéenne...) pour garantir un résultat naturel qui s'intègre parfaitement aux traits du visage.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie générale et dure entre 2 et 3 heures. La technique chirurgicale est adaptée aux spécificités anatomiques de chaque ethnie : peau souvent plus épaisse, cartilages plus fins, base du nez plus large. L'approche peut être fermée ou ouverte selon les modifications nécessaires. Des greffons cartilagineux sont fréquemment utilisés pour renforcer la structure nasale et définir la pointe. Une attention particulière est portée à la réduction des narines si nécessaire, avec des incisions discrètes placées dans les plis naturels. La modification de l'arête nasale et de la pointe tient compte des proportions ethniques du visage pour éviter une 'occidentalisation' non désirée."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites opératoires sont marquées par un œdème plus important et plus long que dans la rhinoplastie conventionnelle, en raison de la plus grande épaisseur de la peau. L'attelle est maintenue 10 à 12 jours. Les ecchymoses se résorbent en 2 à 3 semaines. La période de cicatrisation et de stabilisation est plus longue, pouvant aller jusqu'à 12-18 mois pour le résultat définitif. Un suivi régulier est nécessaire pour surveiller l'évolution de l'œdème. Des massages spécifiques peuvent être prescrits pour améliorer la rétraction cutanée. La reprise des activités sociales est possible après 2 à 3 semaines, mais les activités sportives doivent être évitées pendant 2 à 3 mois."
          },
          {
            "title": "Question diverses",
            "description": "La rhinoplastie ethnique nécessite une expertise particulière et une parfaite connaissance des spécificités anatomiques de chaque origine. Une analyse approfondie des souhaits du patient est essentielle pour définir un projet qui respecte son identité culturelle. Des photographies et simulations 3D sont réalisées pour visualiser le résultat souhaité. Le risque de cicatrices chéloïdes est plus important chez certaines ethnies et nécessite des précautions particulières. L'intervention peut être combinée avec d'autres procédures du visage pour une harmonisation globale. Un suivi à long terme est recommandé car l'évolution peut être plus longue que dans une rhinoplastie classique."
          }
        ]
      },
    "Lifting Cervico-Facial": {
        "mainDescription": "Le lifting cervico-facial est une intervention chirurgicale qui permet de corriger les effets du vieillissement du visage et du cou. Cette chirurgie traite le relâchement cutané, les rides profondes, l'affaissement des tissus et le relâchement du cou, permettant de restaurer l'ovale du visage et de retrouver un aspect plus jeune et naturel.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie générale et dure entre 2h30 et 4 heures. Les incisions sont dissimulées dans les cheveux au niveau des tempes, suivent le contour de l'oreille et se prolongent derrière le pavillon de l'oreille. Le chirurgien procède au décollement de la peau, à la remise en tension du SMAS (système musculo-aponévrotique superficiel) et du platysma, puis à la redistribution des volumes et au retrait de l'excès cutané. Un travail spécifique est réalisé sur le cou pour traiter le relâchement et les bandes platismales. Des techniques complémentaires peuvent être associées comme la lipoaspiration du double menton ou la correction des poches graisseuses. Les sutures sont réalisées avec une attention particulière pour obtenir des cicatrices les plus discrètes possible."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites immédiates nécessitent une hospitalisation de 24 à 48 heures. Un pansement compressif est maintenu pendant 3 à 5 jours. Les fils sont retirés entre le 8ème et le 15ème jour. Des ecchymoses et un œdème sont présents pendant 2 à 3 semaines, nécessitant un arrêt social de 15 à 21 jours. La sensibilité cutanée peut être modifiée temporairement pendant plusieurs semaines à plusieurs mois. Le port d'un bandeau de compression est recommandé la nuit pendant un mois. Les activités sportives peuvent être reprises progressivement après 6 semaines. Le résultat définitif s'apprécie après 3 à 6 mois, lorsque l'œdème résiduel a complètement disparu et que les tissus ont retrouvé leur souplesse."
          },
          {
            "title": "Question diverses",
            "description": "Les résultats d'un lifting cervico-facial sont durables (8 à 12 ans) mais n'arrêtent pas le processus naturel du vieillissement. L'intervention peut être réalisée à partir de 45-50 ans, lorsque les signes de vieillissement deviennent marqués. Le tabac est fortement déconseillé car il compromet la cicatrisation. L'exposition solaire doit être évitée pendant plusieurs mois. Des soins cosmétiques adaptés et une bonne hygiène de vie permettent de prolonger les résultats. L'intervention peut être combinée avec d'autres procédures (blépharoplastie, lipofilling) pour un résultat global harmonieux. Un bilan préopératoire complet est nécessaire pour s'assurer de l'absence de contre-indications médicales."
          }
        ]
      },
    "Deep Plane": {
        "mainDescription": "Le Deep Plane est une technique avancée de lifting cervico-facial qui permet d'obtenir des résultats plus naturels et durables. Cette approche chirurgicale sophistiquée se distingue des liftings traditionnels par son action sur les couches profondes du visage, permettant une redistribution harmonieuse des volumes et une correction plus efficace du relâchement facial.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie générale et dure environ 4 à 5 heures. La technique Deep Plane se caractérise par un décollement et une mobilisation des tissus dans un plan plus profond, sous le SMAS (Système Musculo-Aponévrotique Superficiel). Cette approche permet de libérer complètement les ligaments retenant les tissus et de repositionner l'ensemble de la structure faciale de manière verticale et naturelle. Les incisions sont similaires à celles d'un lifting classique mais le travail en profondeur permet une tension moindre sur la peau, réduisant ainsi l'aspect 'tiré'. Cette technique préserve mieux la vascularisation des tissus et respecte l'anatomie naturelle du visage, notamment au niveau des ligaments et des structures musculaires profondes."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites opératoires nécessitent une hospitalisation de 24 à 48 heures avec une surveillance particulière. Un pansement compressif est maintenu pendant 5 jours. Les œdèmes et ecchymoses sont généralement plus importants que dans un lifting classique mais se résorbent en 2 à 3 semaines. Un arrêt social de 3 semaines est recommandé. Les fils sont retirés entre le 10ème et le 15ème jour. La sensibilité du visage peut être modifiée temporairement pendant plusieurs mois en raison du travail en profondeur. Le port d'un bandeau de compression est conseillé la nuit pendant 6 semaines. Les activités physiques peuvent être reprises progressivement après 8 semaines. Le résultat définitif s'apprécie après 6 mois à 1 an."
          },
          {
            "title": "Question diverses",
            "description": "Le Deep Plane offre des résultats particulièrement durables (10 à 15 ans) grâce à son action sur les structures profondes du visage. Cette technique est particulièrement adaptée aux patients présentant un relâchement important ou ayant déjà bénéficié d'un lifting traditionnel. Elle nécessite une expertise chirurgicale pointue et une parfaite connaissance de l'anatomie faciale. Les risques de tensions excessives et d'aspect artificiel sont minimisés par rapport aux techniques traditionnelles. L'intervention peut être combinée avec d'autres procédures (lipofilling, blépharoplastie) pour un rajeunissement global. Un bilan préopératoire approfondi et une période de récupération plus longue sont nécessaires en raison de la nature plus invasive de la technique."
          }
        ]
      },
    "FaceTite": {
        "mainDescription": "Le FaceTite est une technique innovante de rajeunissement facial mini-invasive qui utilise la radiofréquence assistée pour raffermir et remodeler les tissus du visage et du cou. Cette procédure avancée permet d'obtenir des résultats significatifs sans les cicatrices d'un lifting traditionnel, idéale pour les patients présentant un relâchement cutané léger à modéré.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie locale avec sédation légère et dure environ 1 à 2 heures. La technique utilise une canule émettrice de radiofréquence bipolaire qui est introduite sous la peau à travers de micro-incisions de 2-3mm. L'énergie de radiofréquence est délivrée de manière contrôlée et précise entre une électrode interne et une électrode externe, permettant un chauffage uniforme des tissus à différentes profondeurs. Un système de contrôle thermique en temps réel garantit une température optimale pour la rétraction des tissus sans risque de brûlure. La procédure cible principalement les zones du bas du visage, du cou et du double menton, provoquant une rétraction immédiate du collagène et stimulant sa production naturelle."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites sont relativement simples avec peu de douleurs. Un pansement compressif ou un bandeau est porté pendant 3-4 jours, puis uniquement la nuit pendant 2 semaines. Les ecchymoses sont minimes et l'œdème se résorbe en 5 à 7 jours. La reprise des activités sociales et professionnelles est possible après 2-3 jours. Une sensation de tension et d'engourdissement peut persister quelques semaines. Les micro-incisions cicatrisent rapidement et sont quasi invisibles. Le résultat s'améliore progressivement sur 3 à 6 mois, au fur et à mesure que le nouveau collagène se forme et que la peau se raffermit."
          },
          {
            "title": "Question diverses",
            "description": "Le FaceTite offre une alternative intéressante pour les patients ne souhaitant pas ou n'étant pas prêts pour un lifting chirurgical. Les résultats sont durables (3 à 5 ans) mais moins permanents qu'un lifting traditionnel. La procédure peut être combinée avec d'autres traitements comme le Morpheus8 (radiofréquence micro-needling) ou des injections pour optimiser les résultats. Les meilleurs candidats sont les patients de 35 à 60 ans présentant un relâchement cutané modéré et une bonne élasticité cutanée. Cette technique n'est pas adaptée aux relâchements importants qui nécessitent un lifting chirurgical. Une consultation approfondie est nécessaire pour évaluer l'éligibilité et définir le plan de traitement optimal."
          }
        ]
      },
    "Lipofilling du Visage": {
        "mainDescription": "Le lipofilling du visage est une technique naturelle de réinjection de graisse autologue qui permet de restaurer les volumes du visage et de corriger les signes du vieillissement. Cette procédure utilise la propre graisse du patient, prélevée par lipoaspiration douce, pour combler les zones creuses et redonner au visage son galbe naturel.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention se déroule en trois étapes sous anesthésie locale ou générale légère, durant environ 1 à 2 heures. Première étape : prélèvement de la graisse par lipoaspiration douce dans une zone donneuse (abdomen, cuisses, hanches) à l'aide d'une fine canule. Deuxième étape : purification et préparation de la graisse prélevée par centrifugation ou décantation pour ne conserver que les cellules adipeuses les plus viables. Troisième étape : réinjection précise et minutieuse de micro-quantités de graisse à différents niveaux du visage (pommettes, cernes, sillons naso-géniens, tempes, lèvres) à l'aide de micro-canules. Cette technique permet un remodelage tridimensionnel du visage avec des résultats naturels."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites sont généralement simples avec un œdème qui persiste 5 à 10 jours et des ecchymoses qui se résorbent en 10-15 jours. Les points de prélèvement et d'injection sont quasi invisibles. Un léger sur-remplissage initial est réalisé pour compenser la résorption partielle de la graisse qui survient dans les premières semaines. La reprise des activités sociales est possible après 5-7 jours. Les massages du visage sont à éviter pendant 3 semaines. Une compression légère est recommandée sur les zones de prélèvement pendant 10 jours. Le résultat définitif s'apprécie après 3 mois, une fois que la graisse greffée s'est stabilisée et revascularisée."
          },
          {
            "title": "Question diverses",
            "description": "Le lipofilling offre des résultats naturels et durables, la graisse greffée devenant une partie intégrante du visage. Une partie de la graisse injectée (20 à 30%) se résorbe naturellement, nécessitant parfois une deuxième séance pour optimiser le résultat. Cette technique peut être combinée avec d'autres procédures (lifting, blépharoplastie) pour un rajeunissement global. Les cellules souches présentes dans la graisse ont également un effet bénéfique sur la qualité de la peau. L'intervention est contre-indiquée chez les patients très minces ne disposant pas de zones donneuses suffisantes. Les résultats varient selon les patients et leur mode de vie (variations pondérales importantes, tabac, exposition solaire excessive à éviter)."
          }
        ]
      },
      "Otoplastie":{
        "mainDescription": "L'otoplastie est une intervention chirurgicale qui corrige les oreilles décollées ou proéminentes. Cette chirurgie permet de remodeler le cartilage de l'oreille pour la repositionner plus près de la tête et corriger d'éventuelles asymétries. Elle peut être réalisée dès l'âge de 7 ans, lorsque la croissance des oreilles est quasi terminée, ou à l'âge adulte.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie locale chez l'adulte ou générale chez l'enfant, durant environ 1 à 2 heures. L'incision est dissimulée dans le sillon rétro-auriculaire (derrière l'oreille). Le chirurgien remodèle le cartilage par différentes techniques (points de suture permanents, section ou remodelage du cartilage) pour créer ou recréer les plis naturels de l'oreille. Les modifications portent principalement sur la conque (partie creuse de l'oreille) et l'anthélix (pli du cartilage). Si nécessaire, l'angle entre l'oreille et le crâne est également réduit. Les deux oreilles sont généralement opérées dans la même séance pour assurer la meilleure symétrie possible."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites sont généralement simples avec peu de douleurs. Un bandage compressif type 'bandeau' est porté en continu pendant 5 à 7 jours, puis uniquement la nuit pendant 1 mois pour protéger les oreilles pendant le sommeil. Les fils sont résorbables ou retirés après 10-15 jours. Un œdème et des ecchymoses peuvent persister 2 à 3 semaines. La reprise du travail ou de l'école est possible après 1 semaine. Les activités sportives, notamment celles risquant de heurter les oreilles, sont à éviter pendant 1 à 2 mois. Le port de lunettes doit être prudent les premières semaines. Le résultat définitif s'apprécie après 2 à 3 mois, une fois que l'œdème résiduel a complètement disparu."
          },
          {
            "title": "Question diverses",
            "description": "Les résultats de l'otoplastie sont définitifs, avec un taux de satisfaction très élevé. L'intervention peut améliorer significativement la confiance en soi, particulièrement chez les enfants. Les cicatrices, situées derrière les oreilles, sont généralement très discrètes. Une légère asymétrie peut persister car les oreilles ne sont naturellement jamais parfaitement symétriques. L'intervention peut être prise en charge par l'assurance maladie chez l'enfant avant 16 ans. Les complications sont rares mais peuvent inclure hématome, infection ou anomalie de cicatrisation. La sensibilité des oreilles peut être modifiée temporairement. Les sports de contact doivent être évités pendant la période de cicatrisation."
          }
        ]
      },
      "Bichectomie": {
        "mainDescription": "La bichectomie est une intervention chirurgicale qui consiste à retirer les boules de Bichat, des amas graisseux naturellement présents dans les joues. Cette technique permet d'affiner le visage en créant des pommettes plus saillantes et des joues plus creuses, donnant ainsi un aspect plus anguleux et affiné au visage.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie locale et dure environ 30 à 45 minutes. Le chirurgien pratique une petite incision d'environ 1 cm à l'intérieur de la bouche, au niveau de chaque joue. Les boules de Bichat sont ensuite délicatement identifiées et partiellement retirées de manière symétrique. Le volume retiré est adapté à chaque patient, généralement entre 2 et 5 ml de chaque côté, pour obtenir un résultat naturel et harmonieux. Les incisions sont refermées par des points de suture résorbables. Cette technique ne laisse aucune cicatrice visible puisque les incisions sont intrabuccales."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites sont généralement simples avec un œdème des joues qui persiste 5 à 7 jours. Une alimentation molle et froide est recommandée pendant les premiers jours. Une hygiène buccale rigoureuse est nécessaire avec des bains de bouche antiseptiques pendant 10 jours. La reprise des activités sociales et professionnelles est possible après 2-3 jours, une fois que l'œdème commence à diminuer. Les points de suture se résorbent naturellement en 10-15 jours. Une sensation d'engourdissement temporaire des joues est normale pendant quelques semaines. Le résultat définitif s'apprécie après 2 à 3 mois, lorsque l'œdème a complètement disparu et que les tissus se sont redrapés."
          },
        ]
      },
      "Blépharoplastie": {
        "mainDescription": "La blépharoplastie est une intervention chirurgicale qui permet de corriger les signes de vieillissement des paupières, tels que l'excès de peau, les poches graisseuses et le relâchement musculaire. Cette chirurgie peut être réalisée sur les paupières supérieures et/ou inférieures pour rajeunir le regard et, dans certains cas, améliorer le champ visuel.",
        "content": [
          {
            "title": "Mode opératoire",
            "description": "L'intervention est réalisée sous anesthésie locale avec sédation ou sous anesthésie générale, durant 1 à 2 heures selon l'étendue des corrections. Pour les paupières supérieures, l'incision suit le pli naturel de la paupière permettant le retrait de l'excès cutané et musculaire ainsi que la correction des poches graisseuses. Pour les paupières inférieures, deux approches sont possibles : soit une incision sous les cils (voie externe) pour traiter l'excès cutané, soit une incision à l'intérieur de la paupière (voie transconjonctivale) pour traiter uniquement les poches graisseuses. Le chirurgien peut également réaliser un repositionnement ou une redistribution des amas graisseux pour éviter un aspect trop creux. Les sutures sont très fines et placées dans les plis naturels."
          },
          {
            "title": "Suites opératoires",
            "description": "Les suites immédiates sont marquées par un œdème et des ecchymoses qui persistent 7 à 15 jours. Des larmes artificielles et une pommade cicatrisante sont prescrites pendant les premiers jours. Le sommeil en position semi-assise est recommandé pendant 3-4 jours pour limiter l'œdème. Les fils sont retirés entre le 5ème et le 7ème jour. L'application de froid est conseillée les premiers jours. La reprise du travail est possible après 7-10 jours. Le maquillage peut être repris après 10 jours. Les lunettes doivent être portées avec précaution pendant 15 jours. L'exposition solaire est à éviter pendant 2 mois. Le résultat définitif s'apprécie après 3 à 6 mois, une fois que les tissus ont retrouvé leur souplesse."
          },
        ]
      }
  };