import { useState, useEffect } from "react";
import Questions from "../../components/Questions";
import CeQuilFautSavoirSection from "../../components/CeQuilFautSavoirSection";
import { paragraphContent } from "../../data/corpsData";

const Corps: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [clickedItem, setClickedItem] = useState<string>("Abdominoplastie");
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [contentList, setContentList] = useState<
    { title: string; description: string }[]
  >([]);
  const [mainDescription, setMainDescription] = useState<string>("");
  const [componentKey, setComponentKey] = useState<number>(0);

  const handleClick = (item: string) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setClickedItem(item === clickedItem ? "Abdominoplastie" : item);
      setComponentKey((prevKey) => prevKey + 1);
      setIsTransitioning(false);
    }, 300);
  };

  useEffect(() => {
    setContentList(paragraphContent[clickedItem].content);
    setMainDescription(paragraphContent[clickedItem].mainDescription);
  }, [clickedItem]);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const parallaxFactor = 0.04;
  const backgroundStyle: React.CSSProperties = {
    transform: `translate3d(0, ${-scrollPosition * parallaxFactor}px, 0)`,
  };

  return (
    <section className="page-animation">
      <div
        className="bg-[url('https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-body-1920x4445.jpg')] min-h-[180vh] bg-cover bg-no-repeat bg-fixed bg-center pb-40 w-full bg-parallax active"
        style={backgroundStyle}
      >
        <div className="absolute inset-0 bg-darkB opacity-30"></div>

        <div className="relative">
          <h2 className="text-5xl md:text-5xl lg:text-6xl text-center pt-[15rem] lg:pt-[20rem] pb-5 text-lightB animate-slidein600 mb-20] ">
            CHIRUGIE ESTHÉTIQUE DU CORPS
          </h2>
        </div>

        <div className="relative">
          <h1
            className={`text-3xl md:text-4xl lg:text-4xl font-bold mb-8 py-20 text-lightB uppercase text-center 
                        ${
                          isTransitioning
                            ? "transition-opacity duration-300 opacity-0"
                            : "transition-opacity duration-300 opacity-100"
                        }`}
          >
            {clickedItem}
          </h1>

          <div className="flex flex-col lg:flex-row items-start">
            <div className="w-full lg:w-[16rem] hr-lines-container">
              <ul className="flex flex-col space-y-6 lg:space-y-8">
                {Object.keys(paragraphContent).map((item) => (
                  <li key={item}>
                    <button
                      className={`hr-lines text-lightB text-lg md:text-xl focus:outline-none ${
                        clickedItem === item
                          ? "clicked font-bold"
                          : "!opacity-85"
                      }`}
                      onClick={() => handleClick(item)}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-full lg:w-[39rem] lg:ml-[12rem] mt-8 lg:mt-0">
              <div
                className={`text-lightB px-4 text-lg md:text-xl 
                                ${
                                  isTransitioning
                                    ? "transition-opacity duration-300 opacity-0"
                                    : "transition-opacity duration-300 opacity-100"
                                }`}
              >
                <p>{mainDescription}</p>
              </div>

              <div className="mt-8 lg:mt-[3.5rem] text-lg md:text-xl w-full lg:w-[60rem]">
                <CeQuilFautSavoirSection
                  key={componentKey}
                  iconColor="white"
                  inputControl="transparent"
                  light={true}
                  contentList={contentList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Questions />
    </section>
  );
};

export default Corps;
