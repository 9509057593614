import Button from "./HeroButton";
import { Link } from "react-router-dom";
import breast from "../assets/img/breast.jpg";
import face from "../assets/img/face.jpg";
import body from "../assets/img/body.jpg";

const SurgerySection = () => {
  const images = [
    {
      src: face,
      text: "Visage",
      button: "DÉCOUVRIR",
      link: "/visage",
    },
    {
      src: body,
      text: "Corps",
      button: "DÉCOUVRIR",
      link: "/corps",
    },
    {
      src: breast,
      text: "Seins",
      button: "DÉCOUVRIR",
      link: "/seins",
    },
  ];

  return (
    <div className="flex flex-col sm:flex-row justify-center  sm:space-x-4">
      {images.map((image, index) => (
        <div
          key={index}
          className="relative group sm:h-full   sm:w-1/3 sm:mb-0"
        >
          <div className="overflow-hidden h-[70vh] ">
            <img
              src={image.src}
              alt={image.text}
              className="w-full h-full bg-center object-cover transition-transform duration-500 group-hover:scale-105"
            />
          </div>
          <div className="bg-darkB opacity-30 h-[70vh] w-full absolute top-0 left-0"></div>
          <div className="absolute inset-0 flex flex-col mt-[45vh] justify-center items-center text-white transition-all duration-500">
            <h2 className="text-6xl font-bold mb-4 transition-transform duration-500 text-[#ffffff] group-hover:-translate-y-10">
              {image.text}
            </h2>

            {/* Button wrapper with conditional opacity */}
            <div className="text-lightB duration-500 sm:opacity-0 group-hover:opacity-100">
              {/* Always visible on large screens, hidden until hover on small screens */}
              <Link to={image.link}>
                <Button text={image.button} />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SurgerySection;
