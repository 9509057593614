import React from "react";
import MedecineEsthetiqueSection, {
  MedecineEsthetiqueContent,
} from "./MedecineEsthetiqueSection";
import CompositionSection, { CompositionContent } from "./CompositionSection";
import CeQuilFautSavoirSection from "./CeQuilFautSavoirSection";
import Questions from "./Questions";

export interface ContentItem {
  readonly title: string;
  readonly description: string;
}

export interface DynamicPageProps {
  readonly contentList: ReadonlyArray<ContentItem>;
  readonly iconColor?: "white" | "black";
  readonly inputControl?: "transparent" | "white";
  readonly light: boolean;
  readonly medecineEsthetique?: MedecineEsthetiqueContent;
  readonly composition?: CompositionContent;
  readonly sections?: ReadonlyArray<
    "medecineEsthetique" | "composition" | "ceQuilFautSavoir" | "questions"
  >;
}

const DynamicComponent: React.FC<DynamicPageProps> = ({
  contentList,
  iconColor = "black",
  inputControl = "white",
  light,
  medecineEsthetique,
  composition,
  sections = [
    "medecineEsthetique",
    "composition",
    "ceQuilFautSavoir",
    "questions",
  ],
}) => {
  const renderSection = (section: string) => {
    switch (section) {
      case "medecineEsthetique":
        return medecineEsthetique ? (
          <MedecineEsthetiqueSection
            content={medecineEsthetique}
            iconColor={iconColor}
            inputControl={inputControl}
            light={light}
          />
        ) : null;

      case "composition":
        return composition ? (
          <CompositionSection
            content={composition}
            iconColor={iconColor}
            inputControl={inputControl}
            light={light}
          />
        ) : null;

      case "ceQuilFautSavoir":
        return (
          <div className="sm:px-24 px-5 pb-12 sm:mb-64 mb-24">
            <h2 className="text-4xl sm:text-6xl py-12 animate-slidein600">
              CE QU'IL FAUT SAVOIR
            </h2>
            <CeQuilFautSavoirSection
              iconColor={iconColor}
              inputControl={inputControl}
              light={light}
              contentList={contentList}
            />
          </div>
        );
      case "questions":
        return <Questions />;
      default:
        return null;
    }
  };

  return (
    <div className="page-animation">
      {sections.map((section, index) => (
        <React.Fragment key={section}>{renderSection(section)}</React.Fragment>
      ))}
    </div>
  );
};

export default DynamicComponent;
