import Biographie from "../../components/Biographie";

const BiographiePage: React.FC = () => {
  return (
    <div className="page-animation">
      <Biographie />
    </div>
  );
};

export default BiographiePage;
